import { Navigate } from "react-router-dom";
import { useGoogleAuth, useRouter } from "../hooks";
import { LoaderPage } from "../pages";

export default function withGoogleAuth(Component) {
  return (props) => {
    const { location } = useRouter();
    const { credentials, signout, loadingCredentials } = useGoogleAuth();
    if(loadingCredentials) {
      return (
          <LoaderPage/>
      );
    }
    else {
      if (!credentials) {
        return (
            <Navigate
                to="/auth/google"
                state={{from: location.pathname}}
                replace
            />
        );
      }else {
        return <Component {...props} signout={signout} />;
      }
    }

  };
}
