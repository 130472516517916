import {useCallback, useEffect, useState} from "react";

import {useGoogleAuth, useRouter} from "../../hooks";
import {LoaderPage} from "../../pages";
import {FullPageContainer} from "../../containers";
import {Loader, SolidButton} from "../../components";

export default function GoogleSignInPage() {
  const { signin, credentials, loadingCredentials } = useGoogleAuth();
  const { location, navigate } = useRouter();
  const [reOpenPopup, setReOpenPopup] = useState(false);

  const handleSignIn = useCallback(() => {
    if (signin) {
      signin().catch((error) => {
        setReOpenPopup(true);
        console.error("Error on sign in", error.message);
      });
    }
  }, [signin]);
  
  useEffect(() => {
      const returnUrl = location?.state?.from;

        if (!credentials && !loadingCredentials) {
          handleSignIn();
        } else if (credentials && !loadingCredentials) {
            navigate.replace({ to: returnUrl || "/tutoring" });
        }
        
  }, [credentials, handleSignIn, loadingCredentials, location?.state?.from, navigate, signin]);

  if(process.env.REACT_APP_CI)
    return (
      <FullPageContainer>
        <div className="m-auto">
          <div className={"flex flex-col items-center justify-center"}>
          <div className="flex flex-col items-center justify-center mb-4">
            <h1 className="text-base font-bold text-center mb-4">Loading sign in with popup ( <b>CI ENV ONLY</b> )</h1>
            {reOpenPopup ? <div onClick={()=> handleSignIn()}><SolidButton>Reopen Sign In Popup</SolidButton></div>: null}
          </div>

          <Loader />
          </div>
        </div>
      </FullPageContainer>);

  return <LoaderPage />;
}
