import { useRoutes } from "react-router-dom";
import { useRouter } from "./hooks";
import routes from "./routes";
import * as FullStory from '@fullstory/browser';

export default function App() {
  const router = useRouter();

  FullStory.init({ orgId: 'o-1FK5YB-na1' });
  return useRoutes(routes(router));
}
