import React from "react";
import {Link} from "react-router-dom";
import {ExternalLink} from "../../components";
import SolidButton from "../../components/core/SolidButton"

const formLink = "https://help.stepuptutoring.org/hc/en-us/requests/new?ticket_form_id=28578148175252"

export default function TutorError() {
  return (
    <div className="fixed flex items-center z-10 justify-center top-0 left-0 w-screen h-screen bg-gradient-to-t from-turquoise-lightv2 to-off-white">
    <div className="">
      <div className="flex flex-col bg-white w-full h-full max-w-full rounded-xl overflow-hidden justify-between items-center p-10 shadow-md">
        <p className="font-bold">We cannot find your account in our system.</p>
        <p className="mt-3">Here are a few ways to fix the issue</p>
        <ul className="list-decimal mt-3">
          <li>Try refreshing your page</li>
          <li>Try resigning in with a Step Up Tutoring<br/>email address</li>
          <li>Fill out <ExternalLink href={formLink} className="text-gray-500 underline">this form</ExternalLink> to report a bug</li>
          <div className="pt-5"><Link to="/logout"><SolidButton name="Sign Out"/></Link></div>
        </ul>
      </div>
      </div>
    </div>
  )
}