import React from "react";
import { FacebookIcon, InstagramIcon, TwitterIcon } from "../../../components";

const social = [
  {
    name: "Facebook",
    href: "https://facebook.com/stepuptutoringg",
    icon: (props) => <FacebookIcon {...props} />,
  },
  {
    name: "Instagram",
    href: "https://instagram.com/stepuptutoringg",
    icon: (props) => <InstagramIcon {...props} />,
  },
  {
    name: "Twitter",
    href: "https://twitter.com/stepuptutoringg",
    icon: (props) => <TwitterIcon {...props} />,
  },
  // {
  //   name: "YouTube",
  //   href: "youtube.com/channel/UCSyKBVvrAt3AegA_7OVRwRQ",
  //   icon: (props) => <YouTube {...props} />,
  // },
];

export default function Footer() {
  return (
    <footer className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div className="border-t border-gray-200 py-12 text-center md:flex md:justify-between">
        <p className="text-base text-gray-400">&copy; Step Up Tutoring</p>
        <div className="mt-6 flex justify-center space-x-8 md:mt-0">
          {social.map((item, itemIdx) => (
            <a
              key={itemIdx}
              href={item.href}
              className="inline-flex text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
}
