import React from "react";
import {STEPS_STATUS} from "../../../constants/constants";
import OnboardingStepIcon from "./OnboardingStepIcon";
import "./index.css";

export default function OnboardingStepItem(props) {
  const { step, steps, children, text } = props;
  const [firstSteps, finalSteps] = steps;


  if (firstSteps.status === STEPS_STATUS.COMPLETE) {
    firstSteps.closed = true;
    finalSteps.closed = false;
  } else {
    firstSteps.closed = false;
    finalSteps.closed = true;
  }
  if (firstSteps.status === STEPS_STATUS.COMPLETE && finalSteps.status === STEPS_STATUS.COMPLETE) {
    finalSteps.closed = true;
  }



  return (
    <div>
      {step.status === STEPS_STATUS.COMPLETE ? (
        <>
          <div className="accordion-title mt-4 flex items-center sm:mt-auto">
            <OnboardingStepIcon status={step.status} />
            <span className="ml-4 text-md font-medium text-gray-900">{step.name}
              <span className="font-light text-gray-500"> {step.time}</span>
            </span>
          </div>
          <div>{text ? <p className="flex ml-0 mt-2 text-md text-gray-500 sm:ml-14 sm:text-sm">{text}</p> : null}</div>
          <div>{children}</div>
          <div className="my-5 sm:border sm:border-b-3"></div>
        </>
      ) : step.status === STEPS_STATUS.CURRENT ? (
        <>
          <div className="accordion-title flex items-center sm:flex">
            <OnboardingStepIcon status={step.status} />
              <span className="ml-4 text-md font-medium text-gray-900">{step.name}
                <span className="font-light text-gray-500"> {step.time}</span>
              </span>
          </div>
          <div>{text ? <p className="flex ml-0 mt-2 text-md text-gray-500 sm:ml-14 sm:text-sm">{text}</p> : null}</div>
          <div>{children}</div>
          {/* <div className="my-5 sm:border sm:border-b-3"></div> */}
        </>
      ) : step.name === "Final Steps" ? (
        <>
          <div className="accordion-title flex items-center sm:flex">
            <OnboardingStepIcon status={step.status} />
            <span className="ml-4 text-md font-medium text-gray-900">{step.name}
              <span className="font-light text-gray-500"> {step.time}</span>
            </span>
          </div>
          <div>{text ? <p className="flex ml-0 mt-2 text-md text-gray-500 sm:ml-14 sm:text-sm">{text}</p> : null}</div>
          <div>{children}</div>
          <div className="my-5 sm:border sm:border-b-3"></div>
        </>
      ) : (
        <>
          <div style={{pointerEvents: 'none'}}>
            <div className="items-center hidden sm:flex">
              <OnboardingStepIcon status={step.status}/>
              <span className="ml-4 text-md font-medium text-gray-500">
                {step.name}
              <span className="font-light text-gray-300"> {step.time}</span>
              </span>
            </div>
            <div className="opacity-50">{children}</div>
          </div>
        </>)
      }
    </div>
  );
}