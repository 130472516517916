import React from "react";

export default function Heading(props) {
  const { children, text } = props;

  return (
    <h2 className="pb-2 text-xl leading-6 font-medium text-gray-900">
      {children || text}
    </h2>
  );
}
