import React, { useState } from "react";
import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import { ALERT_VARIANTS } from "../../constants/constants";

const ALERT_ICONS = {
  [ALERT_VARIANTS.FAILURE]: XCircleIcon,
  [ALERT_VARIANTS.INFO]: InformationCircleIcon,
  [ALERT_VARIANTS.SUCCESS]: CheckCircleIcon,
  [ALERT_VARIANTS.WARN]: ExclamationIcon,
};

const ALERT_STYLES = {
  [ALERT_VARIANTS.FAILURE]: {
    bg: "bg-red-50",
    text: "text-red-500",
    hoverBg: "hover:bg-red-100",
    icon: "text-red-400",
  },
  [ALERT_VARIANTS.INFO]: {
    bg: "bg-blue-50",
    text: "text-blue-500",
    hoverBg: "hover:bg-blue-100",
    icon: "text-blue-400",
  },
  [ALERT_VARIANTS.SUCCESS]: {
    bg: "bg-green-50",
    text: "text-green-500",
    hoverBg: "hover:bg-green-100",
    icon: "text-green-400",
  },
  [ALERT_VARIANTS.WARN]: {
    bg: "bg-yellow-50",
    text: "text-yellow-500",
    hoverBg: "hover:bg-yellow-100",
    icon: "text-yellow-400",
  },
  default: {
    bg: "bg-stone-50",
    text: "text-stone-500",
    hoverBg: "hover:bg-stone-100",
  },
};

export default function Alert(props) {
  const { children, dismissable, open, variant = "default" } = props;
  const [isOpen, setIsOpen] = useState(open || true);
  const Icon = ALERT_ICONS[variant];

  return (
    isOpen && (
      <div
        className={classNames(ALERT_STYLES[variant].bg, "my-5 rounded-md p-4")}>
        <div className="flex">
          <div className="flex-shrink-0">
            {Icon && (
              <Icon
                className={classNames(ALERT_STYLES[variant].icon, "w-5 h-5")}
                aria-hidden="true"
              />
            )}
          </div>
          <div
            className={classNames(
              ALERT_STYLES[variant].text,
              "ml-3 text-sm font-medium"
            )}>
            {children}
          </div>
          {dismissable && (
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className={classNames(
                    ALERT_STYLES[variant].bg,
                    ALERT_STYLES[variant].text,
                    ALERT_STYLES[variant].hoverBg,
                    "inline-flex rounded-md p-1.5"
                  )}>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
}
