import { useContext } from "react";
import { AuthContext } from "../contexts";

export default function useGoogleAuth() {
  const {
    googleCredentials: credentials,
    setGoogleCredentials: setCredentials,
    loadingCredentials,
    signInWithGoogle: signin,
    signOutFromGoogle: signout,
  } = useContext(AuthContext);

  return { credentials, setCredentials, signin, signout , loadingCredentials};
}
