import { StrictMode } from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider, FirebaseProvider, UserProvider } from "./contexts";
import App from "./App";
import "./index.css";

render(
  <StrictMode>
    <BrowserRouter>
      <FirebaseProvider>
        <AuthProvider>
          <UserProvider>
            <App />
          </UserProvider>
        </AuthProvider>
      </FirebaseProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);
