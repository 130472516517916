import React from 'react';
import { useParams } from "react-router-dom";
import { LearningPathwayProvider } from "../../../contexts/LearningPathwayContext";
import LearningPathwayDetails from "./LearningPathwayDetails";
import { ContentContainer } from "../../../containers";

function LearningPathway() {
    const {studentId} = useParams();


    return (
        <ContentContainer>
            <LearningPathwayProvider studentId={ studentId }>
                <LearningPathwayDetails studentId={ studentId }/>
            </LearningPathwayProvider>
        </ContentContainer>
    );
}

export default LearningPathway;