import {createContext, useContext, useEffect, useState} from "react";
import {
  browserPopupRedirectResolver,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import {FirebaseContext} from "../contexts";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { auth, services } = useContext(FirebaseContext);
  const [googleCredentials, setGoogleCredentials] = useState(null);
  const [loadingCredentials, setLoadingCredentials] = useState(true);
  const [magicCredentials, setMagicCredentials] = useState(null);
  const sendMagicLink = async (email, returnUrl) => {
    return await services
      .sendEmailSignInLink({ email, returnUrl })
      .then((res) => ({ ok: true, data: res.data }))
      .catch((error) => ({ error }));
  };

  const signInWithGoogle = async () => {
    if (!googleCredentials) {
      const google = new GoogleAuthProvider().setCustomParameters({
        prompt: "select_account",
        hd: "stepuptutoring.org",
      });
      // Multiple auth domains not allowed in CI env var is set by GitHub Actions workflow suggested by firebase team https://github.com/firebase/firebase-js-sdk/issues/7824#issuecomment-1882011196
      if (process.env.REACT_APP_CI) {
        await signInWithPopup(auth, google);
      } else {
        await signInWithRedirect(auth, google, browserPopupRedirectResolver);
      }
    }
  };

  const signInWithMagic = async (stepUpToken) => {
    const res = await services.getCustomAuthToken({ stepUpToken });
    return await signInWithCustomToken(auth, res.data.customToken)
      .then((credentials) => {
        setMagicCredentials(credentials.user);
        return { ok: true, data: credentials.user };
      })
      .catch((error) => ({ error }));
  };

  /**
   * Onboarding Portal Sign Out
   * **/
  const signOutFromMagic = async () => {
    return await signOut(auth)
      .then(() => {
        setMagicCredentials(null);
        return { ok: true };
      })
      .catch((error) => {
        return { error };
      });
  };

  /**
   * Tutor Portal Sign Out
   **/
  const signOutFromGoogle = async () => {
    return await signOut(auth)
      .then(() => {
        setGoogleCredentials(null);

        return { ok: true };
      })
      .catch((error) => {
        return { error };
      });
  };

  useEffect(() => {
      setLoadingCredentials(true);
    onAuthStateChanged(auth, (user) => {
      if (user) {
          let userLoginType = user.providerData[0]?.providerId;

          if (userLoginType === "google.com")
          {
              setGoogleCredentials(user);
          }

          else if (userLoginType === "password")
          {
              setMagicCredentials(user);
          }
      } else {
        setGoogleCredentials(null);
        setMagicCredentials(null);
      }
      setLoadingCredentials(false);
    });
  }, [auth]);

  return (
    <AuthContext.Provider
      value={{
        googleCredentials,
        magicCredentials,
        sendMagicLink,
        loadingCredentials,
        setGoogleCredentials,
        signInWithGoogle,
        signInWithMagic,
        signOutFromGoogle,
        signOutFromMagic,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
