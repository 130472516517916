import React from "react";
import TaskListItem from "./TaskListItem";
import { TASK_STATUS, ONBOARDING_TASKS } from "../../../constants/constants";

export default function TaskList(props) {
  const { items, pad } = props;

  return (
    <div className="ml-2 sm:ml-3 sm:mt-2 sm:border-l-2 sm:mb-4">
    <div className="divide-y divide-gray-200 sm:pl-6">
      {items.map((action, idx) => (
        <div className={`py-${pad}`} key={idx}>
        <TaskListItem
          action={action}
          status={
            action.isCompleted ? TASK_STATUS.COMPLETE : (action.name === ONBOARDING_TASKS.CHAT ? TASK_STATUS.AWAITING: TASK_STATUS.INCOMPLETE)
          }
          length={items.length}
          index={idx}
          key={idx}
        />
        </div>
      ))}
    </div>
    </div>
  );
}
