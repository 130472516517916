import React from "react";
import {Divider, Title} from "../../../components";
import {ContentContainer, EmbedContainer, FullPageContainer,} from "../../../containers";
import {useOnboardingUser} from "../../../hooks";
import {useDocumentTitle} from "../../../hooks/useDocumentTitle";
import WaiverDone from "./WaiverDone";

export default function Waiver() {
  useDocumentTitle('Waiver')
  const { user } = useOnboardingUser();

  if (user?.waiverCompleted) return <WaiverDone />;

  return (
    <FullPageContainer>
      <Title>Waiver</Title>

      <ContentContainer>
        <p role="complementary">
          The tutor waiver is a binding legal agreement between you (the tutor)
          and StepUp Tutoring. It outlines the tutor code of conduct, and
          responsibilities & expectations for our volunteer tutors.
        </p>
      </ContentContainer>


      <Divider />

      <EmbedContainer src="https://tqp1uzj8.paperform.co" title="Waiver"/>
    </FullPageContainer>
  );
}
