import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import LoaderPage from "../utility/LoaderPage";
import { useRouter } from "../../hooks";
import { useCallback, useEffect, useState } from "react";
import useNewUserData from "../../hooks/useNewUserData";

export default function UserCreate() {
    useDocumentTitle('Setting up Appointment Scheduling')
    const { query, navigate } = useRouter();
    const {services} = useNewUserData();
    const [errorCondition, setErrorCondition] = useState(false);
    const [errorConditionMessage, setErrorConditionMessage] = useState(null);


    const createNewOnboardingUser = useCallback(() => {
            return services.createNewOnboardingUser({
                email: query['email'],
                firstname: query['firstname'],
                lastname: query['lastname'],
                phonenum: query['phonenum']
            }).then(value => {
                let result = value.data;

                if(result.error)
                    return { error : result.error , type: result.type}

                return {link: result.instantSignInLink};
            })
                .catch((error) => {
                    return { error };
                });
        
    }, [query, services]);
    
    useEffect( () => {
        createNewOnboardingUser().then(value => {
            if (value.error){
                setErrorCondition(true);
                switch (value.type) {
                    case 'dup-email':
                        setErrorConditionMessage("A tutor with this email already exists");
                        break;
                    case 'invalid-submission':
                        setErrorConditionMessage("Information submitted in the form was not properly formatted please go back and try again");
                        break;
                    default:
                        setErrorConditionMessage("Something went wrong please try again");

                }
                console.error(value.error);
                return;
            }
            let url = new URL(value.link);
            const react_url = `${url.pathname}${url.search}`;
            navigate.replace({to: react_url});
        })
    }, [createNewOnboardingUser, navigate])
    return (
        errorCondition ? <h2>{errorConditionMessage}</h2> :
      <LoaderPage/> 
    );
}
