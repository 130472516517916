import React from "react";
import AvatarPlaceholderIcon from "../../icons/AvatarPlaceholder";
import classNames from "../../../utils/classNames";

export default function UserAvatar(props) {
  const { avatar, theme } = props;

  return (
    <div className={classNames(
      theme === 'green' ? "ring-bgGreen-dark h-4 w-4" : "bg-gray-100 ring-white", "flex rounded-full ring-4 overflow-hidden"
    )}>
      {avatar ? (
        <img src={avatar} alt="user avatar" />
      ) : (
        <AvatarPlaceholderIcon theme={theme} />
      )}
    </div>
  );
}
