import React from "react";
import classNames from "../../utils/classNames";

export default function Title(props) {
  const { children, text, theme, large } = props;

  return (
    <h1 className={classNames(
      theme !== 'green' ? "text-gray-900 font-medium" : "text-blueGreen",
      large ? "text-4xl" : "text-3xl",
      "text-center mb-6 font-medium leading-6"
    )}
    >
      {children || text}
    </h1>
  );
}
