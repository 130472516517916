import React from "react";

export default function Subtitle(props) {
  const { user } = props;

  return (
    <h2 className="text-textGrey text-center text-lg leading-6">
      {user.firstname + ' ' + user.lastname}
    </h2>
  );
}
