import {BookOpenIcon, ChatAlt2Icon, CheckIcon, FingerPrintIcon, XIcon,} from "@heroicons/react/outline";

import {ClipboardIcon} from '../../components';
import {ONBOARDING_TASKS} from "../../constants/constants";

const ACTION_STATUS = {
  COMPLETE: {
    label: "Completed",
    icon: CheckIcon,
    colorForeground: "text-white",
    colorBackground: "bg-turquoise",
  },
  INCOMPLETE: {
    label: "Not Completed",
    icon: XIcon,
    colorForeground: "text-rose-700",
    colorBackground: "bg-rose-100",
  },
  [true]: {
    label: "Completed",
    icon: CheckIcon,
    colorForeground: "text-white",
    colorBackground: "bg-turquoise",
  },
  [false]: {
    label: "Not Completed",
    icon: XIcon,
    colorForeground: "text-rose-700",
    colorBackground: "bg-rose-100",
  },
};


const actions = [
  {
    icon: ChatAlt2Icon,
    name: ONBOARDING_TASKS.CHAT,
    description:
      "A chance to virtually meet you and ask a few questions",
    status: ACTION_STATUS.INCOMPLETE,
    time: "15 minutes",
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
    link: "chat",
    tracked: true,
  },
  {
    icon: ClipboardIcon,
    name: ONBOARDING_TASKS.WAIVER,
    description:
      "A digital, legal agreement between you and Step Up Tutoring",
    status: ACTION_STATUS.INCOMPLETE,
    time: "5 minutes",
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
    link: "waiver",
    tracked: true,
  },
  {
    icon: BookOpenIcon,
    name: ONBOARDING_TASKS.WORKBOOK,
    description:
      "Our self-paced training guide for new tutors",
    status: ACTION_STATUS.INCOMPLETE,
    time: "1-1.5 hours",
    href: "#",
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
    link: "workbook",
    tracked: true,
  },
  {
    icon: FingerPrintIcon,
    name: ONBOARDING_TASKS.BACKGROUND_CHECK,
    description:
      "A mandatory background check required by school district partners",
    status: ACTION_STATUS.INCOMPLETE,
    time: "0.5-1 hour",
    href: "#",
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
    link: "background-check",
    tracked: true,
  },
  // {
  //   icon: PresentationChartBarIcon,
  //   name: ONBOARDING_TASKS.LIVE_TRAINING,
  //   href: "#",
  //   description:
  //     "A one-hour Zoom session where you can learn about Step Up Tutoring",
  //   status: ACTION_STATUS.INCOMPLETE,
  //   time: "1-1.5 hours",
  //   iconForeground: "text-orange-700",
  //   iconBackground: "bg-orange-50",
  //   link: "training",
  //   tracked: true,
  // }
];

export default actions;
