import React from "react";
import {NavLink} from "react-router-dom";
import {Disclosure} from "@headlessui/react";
import {ChevronRightIcon} from "@heroicons/react/solid";
import NavigationItem from "./NavigationItem";
import classNames from "../../../../utils/classNames";

export default function NavigationSection(props) {
  const { name, href, current, children = [], open = false, chevron = true, extraLine, step, theme = '' } = props;

  return (
    <Disclosure as="div" key={name} className="space-y-1" defaultOpen={open}>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full">
            <div
              className={classNames(
                current ? "bg-gray-100 text-teal-800" : theme === 'green' ? "text-blueGreen hover:bg-gray-50 hover:text-gray-900" : "bg-white text-teal-800 hover:bg-gray-50 hover:text-gray-900",
                "mt-6 group w-full flex justify-between items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md"
              )}>
              {href ? (
                <NavLink to={href} className="flex">
                  <props.icon
                    className="mr-3 flex-shrink-0 h-6 w-6 text-blueGreen-700 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="flex-1 text-lg font-extrabold">{name}</span>
                </NavLink>
              ) : props.icon ? (
                <div className="flex">
                  <props.icon
                    className="mr-3 flex-shrink-0 h-6 w-6 text-blueGreen group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="flex-1 text-lg font-extrabold">{name}</span>
                </div>
              ) : (
                <div className="flex">
                  <div
                    className="mr-3 flex-shrink-0 h-6 w-6"
                    aria-hidden="true"
                  />
                  <span className="flex-1 text-lg font-extrabold">{name}</span>
                </div>
              )}
              {/*<Disclosure.Button>*/}
              {children && children.length > 0 && chevron && (
                <ChevronRightIcon
                  className={classNames(
                    open ? "text-gray-400 rotate-90" : "text-gray-300",
                    "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                  )}
                  aria-hidden="true"
                />
              )}
              {/*</Disclosure.Button>*/}
            </div>
          </Disclosure.Button>
          {extraLine ? <p className="group w-full flex items-center pl-11 pr-2 pt-0 pb-2 text-sm font-small text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">{extraLine}</p> : <></>}
          <Disclosure.Panel static={true}>
            {children.map((subItem, idx) => (
              <NavigationItem idx={idx} name={subItem.name} href={subItem.href} external={subItem.external} key={subItem.name} isCompleted={subItem.isCompleted} step={step} icon={subItem.icon} />
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}