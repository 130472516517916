import React from "react";
import { Divider, ExternalLink} from "../components";
import {
  ContentContainer,
  EmbedContainer,
  FullPageContainer,
} from "../containers";
import { useOnboardingUser } from "../hooks";
import bg from "../assets/images/background.png";

export default function InitialChat() {

  const { user } = useOnboardingUser();

  if (!user) return null;

  return (
    <div className="bg-cover bg-center bg-no-repeat"  style={{backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("${bg}")`}}>
    <FullPageContainer>
      <h3 className="mb-10 text-center text-4xl leading-10 font-normal text-turquoise">
      <strong>Mandatory</strong>: Sign up for a chat with a Talent Coordinator</h3>
      <ContentContainer>
          <p  className="mb-6 text-center text-xl leading-9 font-normal text-gray-600">
          Signing up for a meeting is <b>mandatory</b>! <br/>
          We'll keep it casual, ask a few questions, and get to know you better!<br/>
          Check out{" "}
          <span className="font-medium">
            <ExternalLink href="https://docs.google.com/document/d/1M0hR7h3cg8i1K3tBlTT-KBC_RBBPBGlHXrypaYG1D6g/edit">
              <u><b>our interview guide</b></u>
            </ExternalLink>
          </span>{" "}
          to learn what you can expect during our interview. <br/>
          Need some help?{" "}
          <span className="font-medium">
            <ExternalLink href="mailto:charmaine@stepuptutoring.org">
              <u><b>Email</b></u>
            </ExternalLink>
          </span>{" "}
          <b>our Onboarding Manager, Charmaine.</b>
        </p>
      </ContentContainer>
      <Divider />
      <EmbedContainer src={"https://app.acuityscheduling.com/schedule.php?owner=21394641&appointmentType=18777910&email="+user.email+"&firstName="+ user.firstname +"&lastName="+ user.lastname + "&phone=" + user.phone}  title="InitialChat"/>
    </FullPageContainer>
    </div>
  );
}