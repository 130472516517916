import React from "react";

export default function MediumButton(props) {
  const { children, name, type, click, disabled = false } = props;

  return (
    <button disabled={disabled} onClick={click} className={
      type && type === "solid" ? "text-center px-6 py-2 rounded-md shadow-md  text-white bg-turquoise hover:bg-turquoise-dark w-3/4 md:w-fit" :
      "inline-flex items-center px-6 py-2 rounded-md shadow-md border border-gray-300 text-gray-700 hover:bg-gray-50"
      }>
      {children || name}
    </button>
  );
}