import { useContext } from "react";
import { AuthContext } from "../contexts";

export default function useMagicAuth() {
  const {
    magicCredentials: credentials,
    sendMagicLink,
    loadingCredentials,
    signInWithMagic: signin,
    signOutFromMagic: signout,
  } = useContext(AuthContext);

  return {
    credentials,
    loadingCredentials,
    sendMagicLink,
    signin,
    signout,
  };
}
