import React from "react";
import {useOnboardingUser} from "../../../hooks";
import {ONBOARDING_STATUS} from "../../../constants/constants";
import ChatDone from "./ChatDone";
import ChatScheduled from "./ChatScheduled";
import {useDocumentTitle} from "../../../hooks/useDocumentTitle";

export default function Chat() {
  useDocumentTitle('Chat')
  const { user } = useOnboardingUser();

  if (user?.status === ONBOARDING_STATUS.APP_ACCEPTED) return <ChatDone />;

  if (user?.interviewDate || true) return <ChatScheduled />;
}
