import React from "react";
import { ContentContainer, PageContainer } from "../../../containers";
import { Divider, Title } from "../../../components";
import graphicSrc from "../../../assets/images/training.svg";

export default function TrainingDone() {
  return (
    <PageContainer>
      <Title>Live Training</Title>

      <Divider />

      <ContentContainer>
        <div className="flex flex-col items-center space-y-10">
          <img className="max-w-xs" src={graphicSrc} alt="meeting graphic" />

          <div className="text-center">
            <p>You completed the live training!</p>
            <p>
              Now, we know how awesome you are and can't wait for your future
              student to find out, as well!
            </p>
          </div>
        </div>
      </ContentContainer>
    </PageContainer>
  );
}
