import React from "react";
import { Divider, Title } from "../../../components";
import { ContentContainer, EmbedContainer, FullPageContainer } from "../../../containers";
import { useOnboardingUser } from "../../../hooks";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TrainingDone from "./TrainingDone";
import TrainingScheduled from "./TrainingScheduled";

export default function Training() {
  useDocumentTitle('Training')
  const { user } = useOnboardingUser();

  if (user?.liveTrainingCompleted) return <TrainingDone />;

  if (user?.liveTrainingDate) return <TrainingScheduled />;

  return (
    <FullPageContainer>
      <Title>Live Training</Title>

      <ContentContainer>
        <p>
          Live training is a Zoom session, led by members of the Step up team. After this session, you’ll have a good
          understanding of the onboarding process, how matches are processed and what tutoring looks like
        </p>
      </ContentContainer>

      <Divider />

      <EmbedContainer src="https://calendly.com/stepup-tut/training" title="Live Training"/>
    </FullPageContainer>
  );
}
