import React, { useState } from "react";
import { Button, Card, CircularIcon, ExternalLink, Heading, Alert } from "../../../components";
import { ALERT_VARIANTS } from "../../../constants/constants";
import { ChevronUpIcon } from "@heroicons/react/solid";

function AccordionItem({ title, description, content }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          <span className="font-medium">{title}</span>
          <p className="text-sm text-gray-600 mt-1">{description}</p>
        </div>
        <ChevronUpIcon
          className={`${
            isOpen ? "rotate-180 transform" : ""
          } h-5 w-5 text-turquoise-dark`}
        />
      </button>
      {isOpen && (
        <div className="mt-4">
          {content}
        </div>
      )}
    </div>
  );
}

export default function BackgroundCheckOther() {

  const options = [
    {
      title: "Hardcard Fingerprint",
      description: "Recommended option, opens up more student opportunities.",
      content: (
        <>
          <Alert variant={ALERT_VARIANTS.INFO}>
            <p>This method provides access to a wider range of student opportunities and expedited matching. It requires an in-person visit to a local law enforcement agency and takes 4-6 weeks to process.</p>
          </Alert>
          <Card>
            <div className="flex items-baseline mb-4 space-x-4">
              <CircularIcon>1</CircularIcon>
              <Heading>Get Fingerprinted</Heading>
            </div>
            <p>Contact your local law enforcement agency for fingerprinting services. Request "Rolled Fingerprints" on an original <ExternalLink href="https://www.fbi.gov/file-repository/standard-fingerprint-form-fd-258-1.pdf/view?__cf_chl_jschl_tk__=b270a3555accbc1b6edc81b68c43b82bdee186a8-1597954181-0-AWDl6wLPVpw6EUn4CWXExI2TxVr1FY86O3UP4hi5A6IFC9pNEKhFArj7cKvj28mm9ksurbl5RCjB0p40MbQKN6rBmbLFQQSUS4LmCCApkP5Q1hMA9V6Bnr8OD_IO6BjV7LrUel0oE7nNBTJXjL_PekG3PQY47_AE_KS_WmVC1dRzY81ohUjDZG_9pV7EroPCO3V70AqSmvZRpYlDEm7YBasuvTuPnK3GBMNS_JLTOc9tSJ3EzUDhaPbxNcGsSS2sMrMXqUpgkvgYrcSg36R-uk2xDsncTwfAiGMn9oSd5KeK4VJJcaBMC7R6Ei2eqI0OPt7cEeIDIFgrO9-hXXOaSm01maVSIFQ5oMgllnMne5Nt"><span className="text-turquoise-dark hover:underline">hard card (Form FD258)</span></ExternalLink>.</p>
          </Card>

          <Card>
            <div className="flex items-baseline mb-4 space-x-4">
              <CircularIcon>2</CircularIcon>
              <Heading>Fill Out and Mail the Fingerprint Card</Heading>
            </div>
            <p>After completing the fingerprint card, mail the original to Step Up Tutoring for review. We recommend obtaining a tracking number if possible.</p>
            <p>Please include the following information on the <ExternalLink href="https://www.fbi.gov/file-repository/standard-fingerprint-form-fd-258-1.pdf/view?__cf_chl_jschl_tk__=b270a3555accbc1b6edc81b68c43b82bdee186a8-1597954181-0-AWDl6wLPVpw6EUn4CWXExI2TxVr1FY86O3UP4hi5A6IFC9pNEKhFArj7cKvj28mm9ksurbl5RCjB0p40MbQKN6rBmbLFQQSUS4LmCCApkP5Q1hMA9V6Bnr8OD_IO6BjV7LrUel0oE7nNBTJXjL_PekG3PQY47_AE_KS_WmVC1dRzY81ohUjDZG_9pV7EroPCO3V70AqSmvZRpYlDEm7YBasuvTuPnK3GBMNS_JLTOc9tSJ3EzUDhaPbxNcGsSS2sMrMXqUpgkvgYrcSg36R-uk2xDsncTwfAiGMn9oSd5KeK4VJJcaBMC7R6Ei2eqI0OPt7cEeIDIFgrO9-hXXOaSm01maVSIFQ5oMgllnMne5Nt"><span className="text-turquoise-dark hover:underline">hard card (Form FD258)</span></ExternalLink>:</p>
            <div className="pl-5 mt-2 text-center">
              <p>Step Up Tutoring ORI Number: AR126</p>
              <p>Mail Code: 25735</p>
              <p>Address (please address all mail to 'Step Up Tutoring'):</p>
              <p>Step Up Tutoring</p>
              <p>1424 4th STE 214 PMB 1696</p>
              <p>Santa Monica, CA 90401</p>
            </div>
          </Card>

          <Card>
            <div className="flex items-baseline mb-4 space-x-4">
              <CircularIcon>3</CircularIcon>
              <Heading>Wait for Processing</Heading>
            </div>
            <p>Step Up Tutoring will review the card, ensure it's filled out correctly, and submit it to the DOJ. This process may take 4-8 weeks.</p>
          </Card>

          <p className="mt-6 font-bold">If you have more questions about the hardcard fingerprint process, please refer to <ExternalLink href="https://docs.google.com/document/d/1OZzoiAxK1IBAzagv-xLDp2NborouoT_I/edit"><span className="text-turquoise-dark hover:underline">this document</span></ExternalLink> for additional information.</p>
        </>
      ),
      value: "online",
    },
    {
      title: "Online Background Check",
      description: "Quick and convenient option, but delayed matching process.",
      content: (
        <>
          <Alert variant={ALERT_VARIANTS.INFO}>
            <p>This option offers convenience with internet-based completion and quick results, typically within days. However, the matching process might be delayed. It's ideal for California residents seeking a streamlined process.</p>
          </Alert>
          <Card className="my-2">
            <div className="flex items-baseline mb-4 space-x-4">
              <CircularIcon>1</CircularIcon>
              <Heading>Visit Background Check Website</Heading>
            </div>

            <div className="space-y-4">
              <p>Applicants residing outside of California should visit the Secure Volunteer website.</p>

              <div className="max-w-xs mx-auto">
                <ExternalLink href="https://bib.com/secure-volunteer/step-up-tutoring/Choose-Screen">
                  <Button>Visit Secure Volunteer</Button>
                </ExternalLink>
              </div>
            </div>
          </Card>

          <Card>
            <div className="flex items-baseline mb-4 space-x-4">
              <CircularIcon>2</CircularIcon>
              <Heading>Start Process</Heading>
            </div>
            <p>
              Select the <span className="font-medium">Background Screen Type</span>. Click{" "}
              <span className="font-medium">Continue</span> and complete the questions. Lastly, you will be asked to enter
              your payment information.
            </p>
          </Card>

          <Card>
            <div className="flex items-baseline mb-4 space-x-4">
              <CircularIcon>3</CircularIcon>
              <Heading>Wait for Your Results</Heading>
            </div>

            <p>
              We will let you know when we have received your results. This entire process will only take a couple days, so
              you'll be able to start tutoring in no time!
            </p>
          </Card>
        </>
      ),
      value: "fingerprint",
    },
  ];

  return (
    <>
      <Card>
        <Heading>Choose Background Check Method</Heading>
        {options.map((option) => (
          <AccordionItem
            key={option.value}
            title={option.title}
            description={option.description}
            content={option.content}
          />
        ))}
      </Card>
    </>
  );
}