import _ from "lodash";
import React, {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import {Divider, Heading, OnboardingStep} from "../../components";
import {ContentContainer, PageContainer, TaskList} from "../../containers";
import {useOnboardingUser} from "../../hooks";
import {ONBOARDING_STATUS_INDEX, STEPS_STATUS} from "../../constants/constants";
import actions from "../../config/content/onboarding-todo";
import steps from "../../config/content/onboarding-progress-steps";
import {useDocumentTitle} from "../../hooks/useDocumentTitle";
import LoaderPage from '../utility/LoaderPage'

export default function Dashboard() {
  useDocumentTitle('Dashboard')

  const { user, isOnboardingTaskCompleted } = useOnboardingUser();
  const [currentStepIdx, setCurrentStepIdx] = useState([null]);
  const [, setOnboardingSteps] = useState([]);
  const [onboardingTasks, setOnboardingTasks] = useState([]);
  const [mobileStepExpand, setMobileStepExpand] = useState(false);
  const [firstSteps, finalSteps, matchTutor] = steps;

  useEffect(() => {
    if (!user) return;
    setCurrentStepIdx(ONBOARDING_STATUS_INDEX[user.status]);
  }, [user]);

  useEffect(() => {
    if (currentStepIdx === null || !steps) return;
    const userSteps = _.map(steps, (step, idx) => {
      step.idx = idx;
      if (currentStepIdx === idx) {
        step.status = STEPS_STATUS.CURRENT;
      } else if (currentStepIdx > idx) {
        step.status = STEPS_STATUS.COMPLETE;
      } else {
        step.status = STEPS_STATUS.PENDING;
      }
      return step;
    });
    setOnboardingSteps(userSteps);
  }, [currentStepIdx]);

  useEffect(() => {
    if (!user || !actions) return;
    const userTasks = _.map(actions, (action) => ({
      ...action,
      isCompleted: isOnboardingTaskCompleted(action.name),
    }));
    setOnboardingTasks(userTasks);
  }, [user, isOnboardingTaskCompleted]);

  if (!user) {
    return <LoaderPage/>
  } else if (user.interviewDate === null) {
    return (
      <Navigate
                to="/onboarding/chat/schedule"
                replace
              />
    );
  } else {
    return (
      <>
        <PageContainer className="p-12 sm:pt-2">
          <ContentContainer>
            {/* desktop version of welcoming messages */}
            <div className="hidden sm:block">
            <Heading>Welcome to the Onboarding Dashboard, {user.firstname}  👋 </Heading>
            <p>
              We're glad you're here! This portal has everything you need to onboard. Complete the items
              as soon as you can, so we can get you matched with a student. No need to complete these steps in any particular order.
            </p>
            </div>
            {/* mobile version of welcoming messages */}
            <div className="sm:hidden">
            <Heading>Welcome {user.firstname}! 👋</Heading>
            <p>
              We're glad you're here!
            </p>
            </div>
          </ContentContainer>

          <Divider size="small"/>

          <ContentContainer>
            {/* First Steps */}
              {/* start - mobile version only */}
              <div className="sm:hidden">
                <OnboardingStep step={firstSteps} steps={steps}>
                {mobileStepExpand === false ?
                  <TaskList items={onboardingTasks.slice(0,3)} pad="4"/> : <TaskList items={onboardingTasks.slice(0,3)}/>}
                </OnboardingStep>
              </div>
              {/* end - mobile version only */}
              <div className="pt-8 flex hidden sm:flex">
                <OnboardingStep step={firstSteps} steps={steps}>
                  <TaskList items={onboardingTasks.slice(0,3)} />
                </OnboardingStep>
              </div>

            {/* Final Steps */}
              {/* start - mobile version only */}
              <div className="sm:hidden" onClick={() => setMobileStepExpand(true)}>
                {steps[0].status === STEPS_STATUS.PENDING?
                <div className="flex-grow border-t border-gray-200">
                <p className="italic pt-6 text-sm text-gray-500">Background Check available after interview</p>
                </div>
                : ""}
                {steps[0].status === STEPS_STATUS.CURRENT || mobileStepExpand === true?
                <OnboardingStep step={finalSteps} steps={steps}>
                  <TaskList items={onboardingTasks.slice(3,5)} />
                </OnboardingStep>
                : <></>}
              </div>
              {/* end - mobile version only */}

              {/* start - desktop version only */}
              <div className="flex hidden sm:flex">
                <OnboardingStep step={finalSteps} steps={steps}>
                  <TaskList items={onboardingTasks.slice(3,5)} />
                </OnboardingStep>
              </div>

              {/* end - desktop version only */}

            {/* Match with a student */}

              <OnboardingStep step={matchTutor} steps={steps} text="We will take it from here! We utilize your application date to...">
              </OnboardingStep>
          </ContentContainer>
        </PageContainer>
      </>
    );
  }
}