import {ChevronRightIcon} from "@heroicons/react/solid";
import {StepUpLogoHorizontal} from "../components";
import {Footer} from "../containers";
import gitbook from "../assets/images/gitbook.png";
import github from "../assets/images/github.png";
import zenhub from "../assets/images/zenhub.png";


const links = [
  {
    title: "GitBook",
    description: "GitBook helps you publish beautiful docs for your users and centralize your teams' knowledge for advanced collaboration.",
    href: "https://www.gitbook.com/about",
    Image: gitbook,
    external: true,
  },
  {
    title: "GitHub",
    description: "GitHub is where people build software. More than 83 million people use GitHub to discover, fork, and contribute to over 200 million projects.",
    href: "https://github.com/about",
    Image:github,
    external: true,
  },
  {
    title: "ZenHub",
    description: "Zenhub helps your teams get more done faster with time-saving agile automations, fewer meetings, and more visibility in GitHub.",
    href: "https://www.zenhub.com/about-us",
    Image:zenhub,
    external: true,
  },
];

export default function CreditPage(){
  return (
    <div className="bg-white">
      <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="pt-16">
          <StepUpLogoHorizontal className=" max-w-xs mx-auto" />
        </div>
        <div className="max-w-xl mx-auto py-16 sm:py-24">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Credits
            </h1>
          </div>
          <div className="mt-12">
            <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">Thank you to our supporters!</h2>
            <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
              {links.map((link, linkIdx) => (
                <li key={linkIdx} className="relative py-6 flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-turquoise-light">
                      <img alt={'social-icon-' + link.title} src={link.Image}/>
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <h3 className="text-base font-medium text-gray-900">
                      <span className="rounded-sm">
                        <a href={link.href} className="focus:outline-none">
                          <span className="absolute inset-0" aria-hidden="true" />
                          {link.title}
                        </a>
                      </span>
                    </h3>
                    <p className="text-base text-gray-500">{link.description}</p>
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
