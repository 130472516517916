import React from "react";
import { Badge } from "../../../components";
import classNames from "../../../utils/classNames";

export default function TaskListItemBadge(props) {
  const { label, icon, colorForeground, colorBackground, colorOutline } = props;

  return (
    <Badge
      icon={icon}
      text={label}
      className={classNames(
        colorOutline ? `border border-${colorOutline}` : "",
        `inline-flex items-center px-2.5 py-0.5 min-w-[6.5rem] rounded-full text-xs font-medium ${colorForeground} ${colorBackground}`
      )}
    />
  );
}
