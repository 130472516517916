import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./FirebaseContext";


export const LearningPathwayContext = createContext({});

export const LearningPathwayProvider = ({studentId, children}) => {

    const {services} = useContext(FirebaseContext);
    const [pathwayRetrieveError, setPathwayRetrieveError] = useState();
    const [pathway, setPathway] = useState(


            );
    const [loadingLearningPathway, setLoadingLearningPathway] = useState(true);



    const pathwayRet = useCallback(() => {
        return services?.getStudentPathway({studentId})
            .then((res) => {
                return res.data
            }).catch((error) => {
                setPathwayRetrieveError(error.message);
            }).finally(() => setLoadingLearningPathway(false));
    }, [services, studentId]);

    const updateCompleted = useCallback((lessonId) => services?.updateLessonCompletionStatus({studentId, lessonId}), [studentId, services]);

    useEffect(() => {
        pathwayRet().then((res) => {
            if (res)
                setPathway(res);
        });
    }, [pathwayRet]);

    return (<LearningPathwayContext.Provider value={ {
        loadingLearningPathway,
        updateCompleted,
        pathway,
        pathwayRetrieveError,
    } }>
        { children }
    </LearningPathwayContext.Provider>);
};
