import React from "react";
import { NavLink } from "react-router-dom";
import { ExternalLink } from "../../../../components";
import OnboardingStepIcon from "../../../../components/core/OnboardingStep/OnboardingStepIcon";
import { TASK_STATUS } from "../../../../constants/constants";
import verticalLine from "../../../../assets/images/vertical-line.png";

export default function NavigationItem(props) {
  const { name, href, external, isCompleted, idx, step, icon } = props;

  return external ? (
    <ExternalLink
      href={href}
      className="group w-full flex items-center pl-11 pr-2 py-1 ml-2 text-sm font-small text-blueGreen rounded-md hover:text-gray-900 hover:bg-gray-50">
      {name}
    </ExternalLink>
  ) : (
    <>
    <NavLink
      to={href}
      className={idx > 2 && step < 1 ? "group w-full flex items-center pl-11 pr-2 ml-2 text-sm font-small text-gray-200 rounded-md hover:text-gray-900 hover:bg-gray-50" : "group w-full flex items-center pl-11 pr-2 py-1 ml-2 text-sm font-small text-blueGreen rounded-md hover:text-gray-900 hover:bg-gray-50"}>
      {icon ? <><OnboardingStepIcon status={isCompleted? TASK_STATUS.COMPLETE: (idx > 2 && step < 1 ? "": TASK_STATUS.INCOMPLETE)} small /><props.icon className={`-ml-0.5 mr-1.5 h-4 w-4`} /></> : <></>}
      <span>{name}</span>
    </NavLink>
    {icon ?
    <div className="pl-11 ml-2 opacity-20">
      {idx !== 3 ? <img alt="" src={verticalLine} width="20" height="30"></img> : <></> }
    </div> : <></>}
    </>
  );
  // <Disclosure.Button
  //   key={name}
  //   as='a'
  //   href={href}
  //   className='group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50'>
  //   {name}
  // </Disclosure.Button>
}
