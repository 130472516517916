import { FullPageContainer } from "../../containers";
import { Loader } from "../../components";

export default function LoaderPage() {
  return (
    <FullPageContainer>
      <div className="m-auto">
        <Loader />
      </div>
    </FullPageContainer>
  );
}
