import React from "react";
import NavigationSection from "./NavigationSection";
import classNames from "../../../../utils/classNames";

export default function Navigation(props) {
  const { navigation = [], theme } = props;

  return (
    <nav
      className={classNames(
        theme !== 'green' ? "bg-white" : "flex flex-col", "flex-1 px-2 space-y-1"
      )}
      aria-label="Sidebar">
      {navigation.map((item) => (
        <NavigationSection {...item} key={item.name} theme={theme} />
      ))}
    </nav>
  );
}
