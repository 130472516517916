import classNames from "../../utils/classNames";


const content = [
    {
     title: "1-on-1 Support",
     button1: "Sign Up for office hours",
     button2: "Contact Support Team",
     href1: "https://stepuptutoring.as.me/officehours",
     href2: "mailto:tutorsupport@stepuptutoring.org"
     },
    {
     title: "Resources",
     button1: "Help Center",
     button2: "Review Training Resources",
     href1: "https://stepuptutoringhelp.zendesk.com/hc/en-us/categories/25737139094036-Tutors",
     href2: "https://help.stepuptutoring.org/hc/en-us/sections/28482422843028-Training-Materials-Content"
    },
    {
     title: "Match Support",
     button1: "Add or drop your student",
     button2: "Request a substitute",
     href1: "https://airtable.com/shrpWchC5Axp6PEei",
     href2: "https://docs.google.com/forms/d/e/1FAIpQLSdQ6v_Hnt_RReJ7ilzvL1D88y-NOE-0iYK_VOcr6A11DZdYSA/viewform"
    }
];


export default function SupportPage() {
    return (
        <div className="container mx-auto" data-testid="support-1">
        <div className="pt-10">
        <h1 className="mb-4 text-blueGreen text-center text-4xl"> Tutor Support</h1>
        <p className="pb-3 text-slate-600 text-center">We're here to help!</p>
        <br></br>
        {content.map(({ title, button1, button2, href1, href2}, idx) => (
              <div className="space-y-5 container mx-auto pb-10" key={idx}>
                <p className="font-medium text-1xl text-center">{title}</p>
                <div className="flex flex-col text-center">
                <div className="pl-0">
                  <a href={href1} target="_blank" rel="noreferrer" className="px-4" data-testid={button1}>
                  <button
      className={classNames(
           "border-turquoise-dark bg-white text-slate-600 hover:border-turquoise hover:bg-turquoise hover:text-white",
        "w-64 p-3 text-sm rounded-xl drop-shadow-md my-4	"
      )}>
      {button1}
    </button>
                  </a>
                  <a href={href2} target="_blank" rel="noreferrer" className="px-4" data-testid={button2}>
                  <button
      className={classNames(
           "border-turquoise-dark bg-white text-slate-600 hover:border-turquoise hover:bg-turquoise hover:text-white",
        "w-64 p-3 text-sm rounded-xl drop-shadow-md	"
      )}>
      {button2}
    </button>
                  </a>
                </div>
                </div>
              </div>
            ))}
        </div>
        </div>
    );
}