import React from "react";

export default function Badge(props) {
  const { className, icon, text } = props;

  return (
    <span className={className}>
      {/* align badges with/without icons */}
      {icon ? <props.icon className={`-ml-0.5 mr-1.5 h-4 w-4`} /> : <div className={`-ml-5 mr-1.5 h-4 w-4`} />}
      <span className="whitespace-nowrap text-justify">{text}</span>
    </span>
  );
}
