import React from "react";
import classNames from "../../../utils/classNames";
import {Link} from "react-router-dom";
import TaskListItemBadge from "./TaskListItemBadge";
import {ClockIcon} from "@heroicons/react/solid";
import {TASK_STATUS} from "../../../constants/constants";
import {CheckIcon, XIcon} from "@heroicons/react/outline";

const STATUS_BADGE_STYLES = {
  [TASK_STATUS.COMPLETE]: {
    icon: CheckIcon,
    colorForeground: "text-white",
    colorBackground: "bg-turquoise",
  },
  [TASK_STATUS.INCOMPLETE]: {
    icon: XIcon,
    colorForeground: "text-rose-700",
    colorBackground: "bg-rose-100",
  },
  [TASK_STATUS.AWAITING]: {
    colorForeground: "text-yellow-900",
    colorBackground: "bg-yellow-400",
  },
};

export default function TaskListItem(props) {
  const { action, status } = props;

  return (
    <div
      key={action.name}>
      <Link to={action.link}>
      <div className={`grid grid-cols-3 pt-4 pb-4 gap-2 sm:grid-cols-4 sm:ml-6 sm:gap-2.5`}>
        <div className="flex items-center">
          <span
            className={classNames(
              action.iconBackground,
              action.iconForeground,
              "mt-2 mr-4 rounded-lg inline-flex p-3 ring-4 ring-white"
            )}>
            <action.icon className="h-6 w-6" aria-hidden="true" />
          </span>
          <h3 className="text-lg font-medium">
              {action.name}
          </h3>
        </div>
        <div></div>
        <div className="flex mb-auto mt-4 justify-self-end flex-wrap gap-1.5 row-span-2 sm:mt-7">
          <div className="ml-auto">
          {/*Status*/}
          {action.tracked && (
            <TaskListItemBadge
              label={status}
              icon={STATUS_BADGE_STYLES[status].icon}
              colorForeground={STATUS_BADGE_STYLES[status].colorForeground}
              colorBackground={STATUS_BADGE_STYLES[status].colorBackground}
              colorOutline={STATUS_BADGE_STYLES[status].colorOutline}
            />
          )}
          </div>
          {/*Time*/}
          <div className="ml-auto mt-1.5 sm:mt-auto">
          {action.time && (
            <TaskListItemBadge
              label={action.time}
              icon={ClockIcon}
              colorForeground="text-neutral-700"
              colorBackground="bg-neutral-100"
            />
          )}
          </div>
        </div>
        <div className="mt-0 col-span-2">
          <p className="text-sm text-gray-500 mr-2">{action.description}</p>
        </div>
        {/* <div className="invisible sm:visible row-span-2 -mt-6 ml-14">
          <ChevronRightIcon className="w-10 h-10 text-slate-400 stroke-1" />
        </div> */}
      </div>
    </Link>
    </div>
  );
}
