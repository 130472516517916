import React from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";

export default function WorkbookTabLabel(props) {
  const { isCompleted, label } = props;
  return (
    <div className="flex flex-col items-center">
      <span>
        {isCompleted ? (
          <CheckCircleIcon className="w-5 h-5 text-green-500" />
        ) : (
          <XCircleIcon className="w-5 h-5 text-red-500" />
        )}
      </span>
      <span>{label}</span>
    </div>
  );
}
