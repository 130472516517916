import { useContext } from "react";
import { FirebaseContext } from "../contexts";

export default function useNewUserData() {
    const {
        services
    } = useContext(FirebaseContext);

    return {
        services
    };
}
