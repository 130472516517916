import React, {useContext} from "react";
import { Divider } from "../../components";
import { ContentContainerNarrow, PageContainer } from "../../containers";
// import bigwave from "../../assets/images/logo-wave.png"
import { Link } from "react-router-dom";
import useGoogleAuth from "../../hooks/useGoogleAuth";
import useMagicAuth from "../../hooks/useMagicAuth"
import {AuthContext} from '../../contexts/AuthContext'

export default function Logout(props) {
  var context = useContext(AuthContext)
  const googleAuth = useGoogleAuth();
  const magicAuth = useMagicAuth()
  if (context.googleCredentials) {
    googleAuth.signout()
  } else if (context.magicCredentials) {
    magicAuth.signout()
  }

  return (
    <div className="sticky top-0 text-center">
      <PageContainer>
        <Divider />
        <h1 className="mb-6 text-center text-3xl leading-6 font-medium text-gray-900">Log Out</h1>
        <ContentContainerNarrow>
          <div className="flow-root">You have been logged out.</div>
          <Link to="/">
             <button
      className="border-turquoise-dark bg-turquoise text-white hover:border-white hover:bg-white hover:text-turquoise w-64 p-3 text-sm rounded-xl drop-shadow-md">
      Return to home
             </button>
          </Link>
        </ContentContainerNarrow>
      </PageContainer>
    </div>
  );
}
