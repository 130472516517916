import React from "react";
import classNames from "../../../utils/classNames";

export default function Tabs(props) {
  const { onChange, value, tabs } = props;

  return (
    <div>
      <div className="sm:hidden">
        <select
          id="tabs"
          name="tabs"
          className="block w-full border-gray-300 rounded-md"
          defaultValue={value}>
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <span
                key={tab.name}
                className={classNames(
                  tab.name === value
                    ? "font-semibold border-turquoise"
                    : "border-transparent text-gray-600 hover:border-turquoise",
                  "cursor-pointer w-1/4 py-4 px-1 text-center border-b-2 text-sm"
                )}
                onClick={() => onChange(tab)}>
                {tab.label}
              </span>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
