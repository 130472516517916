/**
 * Firebase configuration
 * @type {FirebaseOptions}
 */
const firebaseConfig = process.env.REACT_APP_TEST === "true" ?
  {
    apiKey: "AIzaSyD08FfdjKizOc1HZcuPgNJ947FeXYXd79g",
    authDomain: "test-step-up-portal.web.app",
    projectId: "test-step-up-portal",
    storageBucket: "test-step-up-portal.appspot.com",
    messagingSenderId: "246182095131",
    appId: "1:246182095131:web:4600ce386c684a3841cbbe"
  }
  :{
  apiKey: "AIzaSyBOjNeFphvxWQWMv2yZy5adaJLaBeQ1_do",
  // eslint-disable-next-line no-restricted-globals -- CI env var is set by GitHub Actions workflow suggested by firebase team https://github.com/firebase/firebase-js-sdk/issues/7824#issuecomment-1882011196
  authDomain: "step-up-portal.web.app",
  projectId: "step-up-portal",
  storageBucket: "step-up-portal.appspot.com",
  messagingSenderId: "1056884601547",
  appId: "1:1056884601547:web:784205ff4bf214b3f8214e",
  measurementId: "G-QGBMWFZZRP"
};

export default firebaseConfig;
