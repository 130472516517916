import { Navigate } from "react-router-dom";
import { useMagicAuth, useRouter } from "../hooks";
import {LoaderPage} from "../pages";

export default function withMagicAuth(Component) {
  return (props) => {
    const { location } = useRouter();
    const { credentials, signout , loadingCredentials} = useMagicAuth();
    if(loadingCredentials){
      return (
          <LoaderPage/>
      )
    }else if (!credentials) {
      return (
        <Navigate to="/auth" state={{ from: location.pathname }} replace />
      );
    }
    return <Component {...props} signout={signout} />;
  };
}
