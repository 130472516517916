import {
  BookOpenIcon,
  ChatAlt2Icon,
  HomeIcon,
  IdentificationIcon,
  PencilIcon,
  QuestionMarkCircleIcon
} from "@heroicons/react/solid";
import {EXTERNAL_LINKS} from "../../constants/constants";

const navigation = [
  {
    name: "Dashboard",
    icon: HomeIcon,
    current: false,
    href: "/onboarding",
    open: true,
    chevron: false,
    extraLine: "To-do list",
    children: [
      { name: "Quick chat", id: "Chat", href: "chat", icon: ChatAlt2Icon },
      { name: "Waiver",  id: "Waiver", href: "waiver", icon: PencilIcon},
      { name: "Training modules",  id: "Workbook", href: "workbook", icon: BookOpenIcon },
      { name: "Background Check",  id: "Background Check", href: "background-check", icon: IdentificationIcon },
      // { name: "Live Training", id: "Live Training", href: "training", icon: VideoCameraIcon },
    ],
  },
  {
    name: "Support",
    icon: QuestionMarkCircleIcon,
    current: false,
    open: true,
    chevron: false,
    children: [
      { name: "FAQ", href: EXTERNAL_LINKS.ONBOARDING_FAQ, external: true },
      {
        name: "Tutor Resources",
        href: EXTERNAL_LINKS.TRAINING_RESOURCES,
        external: true,
      },
      {
        name: "Meet with Onboarding Support",
        href: EXTERNAL_LINKS.ONBOARDING_SUPPORT_MEETING,
        external: true,
      },
      { name: "Contact Onboarding Support", href: EXTERNAL_LINKS.EMAIL_US, external: true },
    ],
  },
];

export default navigation;