import React, { useEffect, useState } from "react";
import { CalendarIcon } from "@heroicons/react/solid";
import { Divider, Heading, Title } from "../../components";
import { ContentContainerNarrow, PageContainer } from "../../containers";
import { useTutoringUser } from "../../hooks";
import classNames from "../../utils/classNames";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import useNewUserData from "../../hooks/useNewUserData";

export default function Announcements() {
  useDocumentTitle('Announcement')
  const { user } = useTutoringUser();
  const { services } = useNewUserData();

  const [weeklyAnnouncements, setWeeklyAnnouncements] = useState([]);

  useEffect(() => {
    if (user) {
      setWeeklyAnnouncements(user.weeklyAnnouncements?.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)) || []);
      if (weeklyAnnouncements && weeklyAnnouncements.length > 0) {
        services.setLatestViewedAnnouncement({
          date: weeklyAnnouncements[weeklyAnnouncements.length - 1].created_at,
          id: user.id
        });
      }
    }
  }, [user, services, weeklyAnnouncements]);

  // TODO: Maybe, a loader?
  if (!user) return null;

  return (
    <PageContainer>
      <Title>Announcements</Title>

      <Divider />

      <ContentContainerNarrow>
        <div className="flow-root">
          <ul className="-mb-8">
            {weeklyAnnouncements.length === 0 && (
              <Heading>No Announcements</Heading>
            )}
            {weeklyAnnouncements.map((event, idx) => (
              <li key={idx}>
                <div className="relative pb-8">
                  {idx !== weeklyAnnouncements.length - 1 ? (
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          "bg-turquoise",
                          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                        )}>
                        <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div className="ml-3"> 
                        <p className="text-sm text-gray-500 font-bold"> 
                          {event.title || ""}
                        </p>
                        <p className="text-sm text-gray-500 pt-2"> 
                          {event.content || ""}
                        </p>
                      </div>

                      <div className="text-right text-sm whitespace-nowrap text-gray-500">
                        <time dateTime={event.created_at || ""}>
                          {event.created_at
                            ? new Date(Date.parse(event.created_at)).toLocaleDateString('en-US', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric'
                            })
                            : ""}
                        </time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </ContentContainerNarrow>
    </PageContainer>
  );
}
