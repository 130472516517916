import React from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { STEPS_STATUS } from "../../../constants/constants";

export default function OnboardingStepIcon(props) {
  const { status, small } = props;

  //small - for onboarding portal sidebar only
  if (small){
    if (status === "Completed") {
      return (
        <span className="flex-shrink-0 w-5 h-5 flex items-center justify-center bg-turquoise rounded-full mr-3 group-hover:bg-turquoise">
          <CheckIcon className="w-3 h-3 text-white stroke-white stroke-4" />
        </span>
      );
    }
  
    if (status === "Not Completed") {
      return (
        <span className="flex-shrink-0 w-5 h-5 flex items-center justify-center border border-turquoise rounded-full mr-3">
          <span className="text-turquoise"></span>
        </span>
      );
    }
  
    return (
      <span className="flex-shrink-0 w-5 h-5 flex items-center justify-center border border-gray-300 rounded-full mr-3 group-hover:border-gray-400">
        <span className="text-gray-500 group-hover:text-gray-900"></span>
      </span>
    );
  } else {
    if (status === STEPS_STATUS.COMPLETE) {
      return (
        <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-turquoise rounded-full group-hover:bg-turquoise">
          <CheckIcon className="w-4 h-4 text-white stroke-white stroke-4" />
        </span>
      );
    }
  
    if (status === STEPS_STATUS.CURRENT) {
      return (
        <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-turquoise rounded-full">
          <span className="text-turquoise"></span>
        </span>
      );
    }
  
    return (
      <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
        <span className="text-gray-500 group-hover:text-gray-900"></span>
      </span>
    );
  }

  
}
