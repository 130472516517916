import React from 'react';
import SmallButton from './SmallButton';

export default function Modal(props) {
  return (
    <div className="fixed flex items-center justify-center top-0 left-0 w-screen h-screen bg-opacity-50 bg-gray-500">
      <div className="relative flex flex-col bg-white rounded-3xl overflow-hidden justify-between items-center shadow-md w-full max-w-2xl">
        <div className="w-full">
          <svg className="fill-turquoise" width="45rem" viewBox="0 0 975 150" preserveAspectRatio='xMidYMid meet'>
            <path d="M985.46,0V135.95C643.34,37.32,403.72,95.81,361.78,103.02c-41.94,7.21-128.39,36.01-201.95,40.43C86.27,147.86,0,124.51,0,124.51V0H985.46Z"/>
          </svg>
        </div>
        <div className="absolute font-medium text-white text-xl pt-3 left-8 top-3">{props.title}</div>
          <div className="sm:px-10 px-5 pb-5 w-full">
            <div className="p-4">
              {props.children}
            </div>
            <div className="text-center">
              <SmallButton name="Close" type="solid" click={props.close} />
          </div>
        </div>
      </div>
    </div>
  )
}