import React from "react";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { Button, Divider, ExternalLink, Heading } from "../../../components";
import { ContentContainerNarrow } from "../../../containers";

export default function WorkbookDone(props) {
  const { name } = props;

  return (
    <ContentContainerNarrow>
      <div className="flex flex-col items-center space-y-10">
        <Heading>{name}</Heading>

        <CheckCircleIcon className="w-1/5 sm:max-w-xs text-green-500" />

        <div className="text-center">
          <p className="font-medium">Thank you for your submission!</p>
          <p>You may review this lesson in our Tutor Training Resources.</p>

          <Divider />

          <ExternalLink href="https://docs.google.com/document/d/18wWsqnV59P6a47u4i0IeXQIt2POdjAiPHol3r4i-054/view">
            <Button>Review Tutor Training Resources</Button>
          </ExternalLink>
        </div>
      </div>
    </ContentContainerNarrow>
  );
}
