import React from "react";
import { ComingSoon, PageContainer } from "../../../containers";
import { Title } from "../../../components";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

export default function TutorMessenger() {
  useDocumentTitle('Messenger')
  return (
    <>
      <PageContainer>
        <Title>Messenger</Title>
        <ComingSoon />
      </PageContainer>

      {/*<PageContainer>*/}
      {/*  <Title>Messenger</Title>*/}

      {/*  <ContentContainer>*/}
      {/*    <p>*/}
      {/*      You can send SMS messages to your student's guardian on this page or*/}
      {/*      by texting their number from your phone.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      Your messages are automatically translated to the guardian's*/}
      {/*      preferred language (and vice versa), so you don't have to worry*/}
      {/*      about the language barrier.*/}
      {/*    </p>*/}
      {/*  </ContentContainer>*/}

      {/*  <Divider />*/}

      {/*  <ContentContainer>*/}
      {/*    <TempMessenger />*/}
      {/*  </ContentContainer>*/}
      {/*</PageContainer>*/}

      {/*<div className="h-screen overflow-hidden">*/}
      {/*  <Messenger contacts={user.correspondents} />*/}
      {/*</div>*/}
    </>
  );
}
