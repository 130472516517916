import { Loader, Title } from "../../../components";
import useLearningPathway from "../../../hooks/useLearningPathway";
import PathwayView from "../../../components/features/pathway/PathwayView";
import MediumButton from '../../../components/core/MediumButton.js';
import { EXTERNAL_LINKS } from "../../../constants/constants";
import { ExternalLink } from "../../../components";
import { useTutoringUser } from "../../../hooks";
import React, {useState} from 'react';


function LearningPathwayDetails({ studentId }) {
    const { user } = useTutoringUser();
    const main_student_data = user?.correspondents.find((student) => student.id === studentId);
    const { loadingLearningPathway, pathway, pathwayRetrieveError } = useLearningPathway();
    const student = pathway?.student_obj;
    const [isLinkCopied, setIsLinkCopied] = useState(false);

    const assessmentUrl = `${EXTERNAL_LINKS.UNIT_ONE_PREASSESSMENT[main_student_data?.grade]}?id=${studentId}&name=${main_student_data?.firstname}&grade=${main_student_data?.grade}`;
    
    const handleCopyClick = async (url) => {
      await navigator.clipboard.writeText(url);
      setIsLinkCopied(true);
    };

    if (!user) {
        return (<> <Loader/></>);
    }
    if(!student && !loadingLearningPathway && pathway) {
        return (<>
        <Title text="Can't find student information" theme='green' />
        </>);
    }
    return (
        <div className={"min-h-[50vh] pt-4 px-12"}>
            {loadingLearningPathway && <div className={' flex flex-row justify-center '}><Loader /></div>}
            {!loadingLearningPathway && !pathway &&
                <>
                    <div style={{ maxWidth: "1050px", minHeight: "460px" }} className="mx-auto py-20 px-24 shadow-sm bg-white border rounded-3xl">
                        <div className={'text-left w-fit'}>
                            <Title text={`Spring Post-Assessment`} theme='green' />
                        </div>
                        <div>
                            <div className={'pt-6 pb-4'}>
                                <p>Fantastic job on wrapping up an impressive semester! Your dedication and effort haven't gone unnoticed. It's now time for {main_student_data?.firstname || "your student"} to gauge their progress with their Spring Asssessment. Make sure {main_student_data?.firstname || "your student"} has at least 30 minutes to complete the assessment. Copy the link and paste it into the chat. {main_student_data?.firstname || "Your student"} should complete the assessment on their own computer. Please do not assist them with any questions. 
                                
                                Not every student needs to take this assessment, so if you do not see it for another student do not worry. Once they complete it, you may have to wait a couple minutes for our system to process the results.
                                </p>
                        </div>
                            <div className={'pt-6'}>
                                <button onClick={() => handleCopyClick(assessmentUrl)} className="text-base pr-2">
                                    <MediumButton name={isLinkCopied ? "Copied!" : "Copy Assessment Link"} type="solid" />
                                </button>
                                <ExternalLink
                                    className="text-base"
                                    href={assessmentUrl}
                                >
                                    <MediumButton name="Go to Assessment" type="outline" />
                                </ExternalLink>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                pathway && <PathwayView studentName={student?.firstname} pathway={pathway} studentId={studentId}
                    pathwayRetrieveError={pathwayRetrieveError} />
            }
        </div >
    );
}

export default LearningPathwayDetails;