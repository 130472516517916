import React from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";

function Lesson({ lesson, lessonNumber, checkedLessons, handleCheckboxChange }) {
  return (
    <div key={lesson["id"]} className="mt-3 ml-12 text-lg">
      <div className="flex flex-row text-gray-600 hover:text-black cursor-pointer items-center">
        <div 
          className={`mr-4 font-bold ${checkedLessons[lesson["id"]] ? "text-green-500" : "text-turquoise"}`}
        >
          {lessonNumber}.
        </div>
        <a href={lesson["URL"]} target="_blank" rel="noopener noreferrer" className="flex flex-row">
          <span className="mr-3 text-lg ">{lesson["Lesson_Name"]}</span>
          <ArrowRightIcon className="h-6 w-6 text-gray-700 hover:text-black" />
        </a>
      </div>
    </div>
  );
}

export default Lesson;
