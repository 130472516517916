import { useEffect, useState, useCallback } from "react";
import Modal from '../../components/core/Modal';
import useNewUserData from '../../hooks/useNewUserData';
import { Link } from "react-router-dom";
import useScrollDirection from "../../hooks/useScrollDirection"
import classNames from "../../utils/classNames";

export default function Notification({ user }) {

  const [ announcementIcon, setAnnouncementIcon ] = useState(null);
  const [ showAnnouncements, setShowAnnouncements ] = useState(null);
  const {services} = useNewUserData();
  const scrollDirection = useScrollDirection();
  var width = window.innerWidth;


  const setLatestViewedAnnouncement = useCallback(function(date) {
    if (services) {
      services.setLatestViewedAnnouncement({
        date,
        id: user.id
      });
    }
  }, [services, user.id]);
  useEffect(()=>{
    if(user && user.weeklyAnnouncements)
    user.weeklyAnnouncements = user.weeklyAnnouncements.sort((a,b) => Date.parse(b.created_at) - Date.parse(a.created_at));
  },[user]);

  useEffect(() => {
    if (user?.weeklyAnnouncements) {
      let newAnnouncements = user.weeklyAnnouncements;
      if (user?.latestViewedAnnouncement) {
        // filter announcements that have already been viewed
        newAnnouncements = user.weeklyAnnouncements.filter(announcement => {
          return new Date(announcement.created_at) > user.latestViewedAnnouncement;
        });
      }
      if (newAnnouncements.length > 0) {
        if (Math.min(window.innerWidth, window.innerHeight) < 640) {
          // set bell icon
          setAnnouncementIcon(newAnnouncements.length);
        } else {
          setShowAnnouncements(newAnnouncements);
          // set latest viewed announcement
          setLatestViewedAnnouncement(newAnnouncements[newAnnouncements.length - 1].created_at)
        }
      }
    }
  }, [user, services, setLatestViewedAnnouncement]);

  const formatDate = function(date) {
    return  new Date(Date.parse(date)).toLocaleString('en-us', {dateStyle:"medium"});
  }

  const bell = () => {
    return (
      <svg className="w-10 h-10" viewBox="0 0 25 25" data-testid="bell-icon">
      <path
        d="M9.9 15.5H4.2c-.2 0-.3-.1-.3-.3-.1-.6.1-1.1.3-1.7.2-.4.5-.8.8-1.2.3-.5.5-1 .5-1.6v-2c0-.6.1-1.3.2-1.9.2-1.1 1-1.9 2.1-2.4.4-.2.7-.4.8-.8 0-.1.1-.1.1-.2.3-.7 1.1-1 1.8-.6.2.1.3.2.4.4l.3.6c.1.3.3.5.6.6 1.5.6 2.2 1.7 2.4 3.2.1.7 0 1.4.1 2.1V11c0 .5.2 1 .5 1.4l.9 1.5c.2.4.3.9.2 1.4 0 .2-.1.2-.3.2H9.9z"
        style={{
          fill: "#f9ed32",
        }}
      />
      <path
        d="M17.6 6.8c0 .2-.1.4-.3.4-.2 0-.4-.1-.5-.3 0-.2-.1-.3-.1-.5-.4-1.6-1.2-3-2.6-4l-.2-.2c-.2-.1-.2-.4-.1-.5.1-.2.4-.2.5-.1.4.2.7.4 1 .7 1.3 1.2 2 2.6 2.3 4.5 0-.1 0-.1 0 0zm-15.4 0c.1-.7.3-1.4.6-2 .5-1.2 1.3-2.3 2.4-3.1.1 0 .1-.1.2-.1.2-.1.4-.1.6.1 0 .2 0 .4-.2.6-.4.3-.8.6-1.1 1-1 1-1.5 2.1-1.7 3.5-.1.3-.4.5-.6.3-.1-.1-.2-.2-.2-.3zm5.6 9.5H12c0 .7-1 1.5-1.9 1.6-1 .2-2.1-.6-2.3-1.6zM4 7c.2-.5.3-1 .5-1.4.3-.8.9-1.5 1.5-2.1.2-.2.4-.2.6 0 .2.2.1.4-.1.6-.9.8-1.5 1.8-1.7 3 0 .3-.2.4-.5.4-.1-.1-.2-.2-.3-.5zm11.7 0c0 .2-.1.4-.3.4-.2 0-.4-.1-.4-.3 0-.1-.1-.3-.1-.4-.3-1-.8-1.9-1.6-2.6l-.1-.1c-.2-.1-.2-.3 0-.5.1-.2.4-.2.6 0 .2.2.5.5.7.8.6.7 1 1.6 1.2 2.6V7z"
        style={{
          fill: "#fff200",
        }}
      />
      <circle
        cx={13.6}
        cy={11.2}
        r={4}
        style={{
          fill: "#ed1c24",
        }}
      />
      <path
        style={{
          fill: "none",
        }}
        d="M10.5 8H20v10.8h-9.5z"
      />
      <text data-testid="bell-text"
        transform="translate(10.493 14.41)"
        style={{
          fontSize: 9,
          fontFamily: "'ArialMT'",
          fill: "#fff",
        }}
      >
        {announcementIcon}
      </text>
    </svg>
    );
  }

  if (announcementIcon) {
    return (
      <div className={classNames("top-0 left-0 right-0 z-50 h-10 flex mt-2 mr-3 justify-end",
      width < 640 ? "fixed transition-all duration-500" : "",
      scrollDirection === "down" && width < 640 ? "-top-24" : "top-0")}>
        <div onClick={() => {
          user.latestViewedAnnouncement = Infinity;
          setLatestViewedAnnouncement(user.weeklyAnnouncements[user.weeklyAnnouncements.length - 1].created_at);
          }}>
          <Link to="/tutoring/announcements">
            {bell()}
          </Link>
        </div>
      </div>
    );
  }

  if (showAnnouncements) {
    return (
      <Modal close={()=>{
        user.latestViewedAnnouncement = Infinity;
        setShowAnnouncements(null)}} title="Announcements">
        <ul>
          {showAnnouncements.map((announcement, index) => {
            return (<li className="pb-4"key={index}>
              <p className="text-gray-500" data-testid="announcement-date">
                {formatDate(announcement.created_at)}
              </p>
              <p className="pb-1 font-medium">
                {announcement.title}
              </p>
              <p className="ml-2 ">
                {announcement.content}
              </p>
            </li>)
          })}
        </ul>
      </Modal>
    );
  }

  return null;
}
