import React, { useState } from 'react';
import { PlusIcon } from "@heroicons/react/solid";
import { MinusIcon } from "@heroicons/react/outline";

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className='text-gray-600 hover:text-black cursor-pointer group mt-3 ml-8 flex flex-row items-center' onClick={() => setIsOpen(!isOpen)}>
        { !isOpen ? <PlusIcon className='h-5 w-5 text-gray-700' /> : <MinusIcon className='h-5 w-5 text-gray-700' /> }
        <span className='ml-6 text-lg'>{title}</span>
      </div>
      { isOpen && content }
    </>
  );
}

export default Accordion;
