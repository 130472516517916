import React, { useState } from "react";
import { Loader } from "../../components";
import classNames from "classnames";

// TODO: Consider iframe security
// TODO: Consider fallback

export default function EmbedContainer(props) {
  const { src , title} = props;
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="flex flex-col flex-grow">
      {!isLoaded && (
        <div className="mx-auto my-12">
          <Loader />
        </div>
      )}

      <iframe
        className={classNames(isLoaded ? "flex flex-grow" : "hidden")}
        src={src}
        title={title}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
}
