import { BookOpenIcon, ClipboardListIcon, GlobeAltIcon } from "@heroicons/react/outline";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { StepUpLogoHorizontal } from "../components";
import { Footer } from "../containers";

const links = [
  {
    title: "Step Up Tutoring",
    description: "Our main website where you can learn more about Step Up Tutoring",
    href: "https://www.stepuptutoring.org",
    icon: GlobeAltIcon,
    external: true,
  },
  {
    title: "Onboarding Portal",
    description: "Our portal to guide aspiring tutors through their onboarding and training",
    href: "/onboarding",
    icon: ClipboardListIcon,
  },
  {
    title: "Tutoring Dashboard",
    description: "Our dashboard of resources for our matched tutors to help with their tutoring sessions ",
    href: "/tutoring",
    icon: BookOpenIcon,
  },
];

export default function PageNotFound() {
  return (
    <div className="bg-white">
      <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="pt-16">
          <StepUpLogoHorizontal className=" max-w-xs mx-auto" />
        </div>
        <div className="max-w-xl mx-auto py-16 sm:py-24">
          <div className="text-center">
            <p className="text-sm font-semibold text-turquoise uppercase tracking-wide">404 error</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              This page does not exist.
            </h1>
            <p className="mt-2 text-lg text-gray-500">The page you are looking for could not be found.</p>
          </div>
          <div className="mt-12">
            <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">Popular pages</h2>
            <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
              {links.map((link, linkIdx) => (
                <li key={linkIdx} className="relative py-6 flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-turquoise-light">
                      <link.icon className="h-6 w-6 text-black" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <h3 className="text-base font-medium text-gray-900">
                      <span className="rounded-sm">
                        <a href={link.href} className="focus:outline-none">
                          <span className="absolute inset-0" aria-hidden="true" />
                          {link.title}
                        </a>
                      </span>
                    </h3>
                    <p className="text-base text-gray-500">{link.description}</p>
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
