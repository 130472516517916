import AccountMenu from "./AccountBar/AccountMenu";
import Logo from "./Logo";
import Navigation from "./Navigation/Navigation";
import classNames from "../../../utils/classNames";

export default function Sidebar(props) {
  const { navigation, onSignOut, title, theme, user } = props;

  return (
    <div
      className={classNames(
        theme !== 'green' ? "border-r border-gray-200 bg-white" : "", "flex-1 flex flex-col min-h-0"
      )}>
      <div className="flex-1 flex flex-col mt-5 pb-4 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-4 mb-8">
          <Logo theme={theme} linkTo="./" />
        </div>

        {theme && theme !== 'green' && (
          <div className="my-3 pl-5 text-xs font-semibold text-gray-900 uppercase tracking-wider">
            <h1>{title}</h1>
          </div>
        )}

        <div className="flex-grow flex flex-col">
          <Navigation navigation={navigation} theme={theme} user={user} />
        </div>
      </div>
      <div className="flex-shrink-0 flex p-4">
        {user && <AccountMenu onSignOut={onSignOut} user={user} theme={theme} />}
      </div>
    </div>
  );
}
