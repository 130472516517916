import { Navigate } from "react-router-dom";
import { withGoogleAuth, withMagicAuth, withOnboardingData, withTutoringData, } from "./hocs";
import { SidebarLayout, TutorPortalSidebar } from "./layouts";
import {
  CreditsPage,
  GoogleSignInPage,
  InitialChat,
  LandingPage,
  Logout,
  MagicSignInPage,
  OnboardingBackgroundCheck,
  OnboardingChat,
  OnboardingDashboard,
  OnboardingTraining,
  OnboardingUserCreate,
  OnboardingWaiver,
  OnboardingWorkbook,
  PageNotFound,
  SupportPage,
  TutorDashboard,
  TutoringAnnouncements,
  TutorMessenger,
  TutorWeeklyForm
} from "./pages";
import compose from "./utils/compose";
import LearningPathway from "./pages/tutoring/LearningPathway/LearningPathway";

const OnboardingSidebarLayout = compose(
  withMagicAuth,
  withOnboardingData
)(SidebarLayout);

const TutoringSideBarLayout = compose(
  withGoogleAuth,
  withTutoringData
)(TutorPortalSidebar);

const ChatSchedule = compose(
  withMagicAuth,
  withOnboardingData
)(InitialChat);

const routes = (router) => {
  const { location, query } = router;

  return [
    { path: "/", element: <LandingPage /> },
    { path: "/home", element: <Navigate to="/" replace /> },

    // Auth
    { path: "auth", element: <MagicSignInPage /> },
    {
      path: "auth/google",
      element: <GoogleSignInPage />,
    },
    { path: "new-onboarding-user", element: <OnboardingUserCreate/> },
    {
      // @deprecated
      path: "signin.html",
      element: (
        <Navigate
          to={query.stepupToken ? `/auth/${location.search}` : "/auth"}
          replace
        />
      ),
    },

    // Onboarding Portal

    { path: "onboarding/chat/schedule", element: <ChatSchedule /> },

    {
      path: "onboarding",
      element: <OnboardingSidebarLayout />,
      children: [
        { index: true, element: <OnboardingDashboard /> },
        { path: "dashboard", element: <OnboardingDashboard /> },
        { path: "chat", element: <OnboardingChat /> },
        { path: "waiver", element: <OnboardingWaiver /> },
        { path: "workbook", element: <OnboardingWorkbook /> },
        {
          path: "background-check",
          element: <OnboardingBackgroundCheck />,
        },
        { path: "training", element: <OnboardingTraining /> },
      ],
    },

    // Tutor Portal
    {
      path: "tutoring",
      element: <TutoringSideBarLayout />,
      children: [
          {index: true, element: <TutorDashboard/>},
          {path: "dashboard", element: <TutorDashboard/>},
          {path: "messenger", element: <TutorMessenger/>},
          {path: "learning-pathway/:studentId", element: <LearningPathway/>},
          {path: "session-form", element: <TutorWeeklyForm/>},
          {path: "announcements", element: <TutoringAnnouncements/>},
          {path: "support", element: <SupportPage/>}
      ],
    },
    { path: "logout", element: <Logout /> },
    // Credit Page
    {
      path: "credits",
      element: <CreditsPage/>,
    },
    // Unknown
    {
      path: "*",
      element: <PageNotFound />,
    }

  ];
};

export default routes;
