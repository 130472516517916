import LargeButton from "../../components/core/LargeButton";

const content = [
    {
     title: "1-on-1 Support",
     button1: "Sign Up for office hours",
     button2: "Contact Support Team",
     href1: "https://stepuptutoring.as.me/officehours",
     href2: "mailto:tutorsupport@stepuptutoring.org"
     },
    {
     title: "Resources",
     button1: "Frequently asked questions",
     button2: "Review Training Manuals",
     href1: "https://help.stepuptutoring.org/hc/en-us/categories/25737139094036-For-Tutors",
     href2: "https://help.stepuptutoring.org/hc/en-us/sections/28482422843028-Training-Materials-Content"
    },
    {
    title: "Substitution Requests",
    button1: "Request a Substitute",
    button2: "Fill in as a Substitute",
    href1: "https://airtable.com/appmQ8Do0E1egGZs6/shr622q14kusdaAJ3",
    href2: "https://stepupsubs.softr.app/"
    }
];


export default function SupportPage() {
    return (
        <div className="container mx-auto" data-testid="support-1">
        <div className="pt-10">
        {content.map(({ title, button1, button2, href1, href2}, idx) => (
            <div className="space-y-5 container mx-auto pb-10" key={idx}>
            <p className="text-lg font-extrabold text-center text-blueGreen">{title}</p>
                <div className="flex flex-col text-center">
                <div className="pl-0">
                  <a href={href1} target="_blank" rel="noreferrer" className="px-4" data-testid={button1}>
                  <LargeButton primaryColor="white" type="solid" >{button1}</LargeButton>
                  </a>
                  <a href={href2} target="_blank" rel="noreferrer" className="px-4" data-testid={button2}>
                  <LargeButton primaryColor="white" type="solid" >{button2}</LargeButton>
                  </a>
                </div>
                </div>
              </div>
            ))}
        </div>
        </div>
    );
}