import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-sut-horizontal.png";

export default function StepUpLogoHorizontal(props) {
  return (
    <Link to="/">
      <img src={logo} alt="Step Up Tutoring horizontal logo" {...props} />
    </Link>
  );
}
