import { Link } from "react-router-dom";
import {
  Button,
  ExternalLink,
  StepUpLogoHorizontal,
  SolidButton
} from "../components";

import { Footer } from "../containers";
import { EXTERNAL_LINKS } from "../constants/constants";

const content = [
  {
    heading: "Currently Onboarding?",
    description:
      "Complete the rest of your onboarding steps and get paired with a student in no time!",
    button: "Go to the Onboarding Portal",
    href: "onboarding",
    isExternal: true,
  },
  {
    heading: "Already a Step Up Tutor?",
    description:
      "Sign in to with your Step Up email to access your Tutor Dashboard!",
    button: "Go to the Tutor Dashboard",
    href: "tutoring",
    isExternal: false,
  },
];

const formLink = "https://help.stepuptutoring.org/hc/en-us/requests/new?ticket_form_id=28578148175252";

export default function LandingPage() {
  return (
    <>
      <StepUpLogoHorizontal className="absolute left-10 top-10 w-28 lg:w-48 mx-auto" />
      <p className="absolute right-0 md:right-10 top-10 w-48 semibold text-turquoise lg:text-white"><ExternalLink href={formLink}>Report an Issue</ExternalLink></p>

      <div className="lg:grid grid-cols-2 h-auto font-sans"	>
        <div className="lg:px-20 mb-10 md:mb-0 bg-white h-auto lg:h-screen">
            <div className="px-14 md:px-16 lg:pr-40 space-y-12 pt-28 lg:pt-40">
            <h1 className="md:text-5xl text-4xl text-turquoise font-bold">Student Sign In</h1>

              <div className="mb-5 pl-2">
                <p className="text-slate-700 font-medium pb-3 text-3xl">
                Active Step Up student?
                </p>
                <p className="text-md text-gray-500 pb-6">
                Sign in with your tutoring code to access your student portal!
                </p>

              <ExternalLink href={EXTERNAL_LINKS.STUDENT_PORTAL}>
                <SolidButton name="Go to the Student Portal"/>
              </ExternalLink>
              <p className="pt-10 lg:pt-24 text-gray-500 underline font semibold text-lg"><ExternalLink href={EXTERNAL_LINKS.STUDENT_APPLICATION}>Haven't Applied Yet?</ExternalLink></p>
              </div>
            </div>
        </div>


      <div className="sm:pt-20 pb-10 bg-turquoise h-auto lg:h-auto px-14 md:px-16 lg:pr-20 space-y-12 font-sans">
        <br></br>
        <h1 className="md:text-5xl text-4xl text-white font-bold pt-2">Tutor Sign In</h1>
          {content.map(({ heading, description, button, href, isExternal }, idx) => (
              <div className="space-y-4" key={idx}>
                <div>
                  <p className="text-slate-700 font-medium pb-3 text-3xl">{heading}</p>
                  <p className="text-white space-y-5">{description}</p>
                </div>
                <div>
                   {isExternal ?
                       <ExternalLink href={href}>
                          <Button className="" name={button}/>
                       </ExternalLink>
                       :
                       <Link to={href}>
                          <Button className="" name={button}/>
                       </Link>
                   }

                </div>
              </div>
            ))}
            <p className="underline text-white font semibold text-lg"><ExternalLink href={EXTERNAL_LINKS.VOLUNTEER_TUTOR_APPLICATION}>Haven't Applied Yet?</ExternalLink></p>
        </div >
      </div>
      <div className="h-auto"><Footer /></div>
    </>
  );
}
