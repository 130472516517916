import React from "react";
import {
  Alert,
  Button,
  Card,
  Divider,
  ExternalLink,
  Heading,
  Title,
} from "../../../components";
import {
  ContentContainer,
  ContentContainerNarrow,
  PageContainer,
} from "../../../containers";
import { useOnboardingUser } from "../../../hooks";
import { ALERT_VARIANTS } from "../../../constants/constants";

export default function ChatScheduled() {
  const { user } = useOnboardingUser();

  if (!user) {
    return <p>Loading...</p>;
  }

  const formatInterviewDate = (date) => {
    const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  return (
    <PageContainer>
      <Title>Quick Chat</Title>

      <ContentContainer>
        <Card>
          <div className="flex flex-col items-center mx-auto">
            <Heading>Your interview is confirmed!</Heading>
            <p className="font-medium">{formatInterviewDate(user.interviewDate)}</p>

            <p>Check your inbox for your meeting link.</p>

            {/* TODO: Wrap action in confirmation dialog */}
            <Alert variant={ALERT_VARIANTS.WARN}>
              <p className="text-gray-800">
                Once the button below is clicked, your appointment will be
                immediately cancelled. This cannot be undone.
              </p>
            </Alert>

            <div className="w-full 2xl:w-1/4 mx-auto flex justify-center">
              <ExternalLink
                href={`https://us-central1-acuity-82682.cloudfunctions.net/rescheduleAppointment?appointmentId=${user.acuityAppointmentID}&email=${user.email}`}>
                <Button>I want to cancel and reschedule</Button>
              </ExternalLink>
            </div>
          </div>
        </Card>
      </ContentContainer>

      <Divider />

      <ContentContainerNarrow>
        <div className="flex flex-col items-center mx-auto">
          <Heading>Preparing for your interview</Heading>
          <p>
            Your application can only tell us so much. We'd like to learn more
            about you and your interest in volunteering with us. Be prepared to
            tell us about yourself, including why you decided to apply to
            volunteer! While prior tutoring experience is not necessary, we want
            to hear about your past experiences and hear about how they relate
            to tutoring and mentoring.
          </p>

          <Divider />

          <div className="px-10">
            <ul className="list-disc">
              <li>Be yourself!</li>
              <li>
                Think of examples to support your answers to questions as they
                are asked.
              </li>
              <li>
                Be curious and ask our team some questions! What do you want to
                know about our tutoring program and organization?
              </li>
            </ul>
          </div>

          <Divider />

          <div className="w-full 2xl:w-1/4 mx-auto flex justify-center">
            <ExternalLink href="https://docs.google.com/document/d/1M0hR7h3cg8i1K3tBlTT-KBC_RBBPBGlHXrypaYG1D6g/view">
              <Button>Interview Guide</Button>
            </ExternalLink>
          </div>
        </div>
      </ContentContainerNarrow>
    </PageContainer>
  );
}