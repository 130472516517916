import React from "react";
import { EmbedContainer, FullPageContainer } from "../../../containers";
import { Divider, Title } from "../../../components";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

export default function WeeklyForm() {
  useDocumentTitle('Weekly Form')
  return (
    <FullPageContainer>
      <Title>Weekly Session Form</Title>

      <Divider />

      <EmbedContainer src="https://airtable.com/embed/shrNNQzXOJOP7WtEm?backgroundColor=yellow" title="WeeklyForm"/>
    </FullPageContainer>
  );
}
