import React from "react";
import {
  Button,
  Card,
  Divider,
  ExternalLink,
  Heading,
  Title,
} from "../../../components";
import { ContentContainer, PageContainer } from "../../../containers";
import { useOnboardingUser } from "../../../hooks";

export default function TrainingScheduled() {
  const { user } = useOnboardingUser();

  return (
    <PageContainer>
      <Title>Live Training</Title>

      <Divider />

      <ContentContainer>
        <Card>
          <div className="flex flex-col items-center mx-auto">
            <Heading>Your training session is confirmed!</Heading>
            <p className="font-medium">{user.liveTrainingDate}</p>

            <Divider />

            <div className="w-full 2xl:w-1/4 space-y-2">
              <div>
                <ExternalLink
                  src={`https://calendly.com/reschedulings/${user.calendlyInviteeID}`}>
                  <Button>Reschedule</Button>
                </ExternalLink>
              </div>

              <div>
                <ExternalLink
                  src={`https://calendly.com/cancellations/${user.calendlyInviteeID}`}>
                  <Button>Cancel</Button>
                </ExternalLink>
              </div>
            </div>
          </div>
        </Card>
      </ContentContainer>
    </PageContainer>
  );
}
