import { useContext } from "react";
import { LearningPathwayContext } from "../contexts/LearningPathwayContext";

export default function useLearningPathway() {
    const {
        loadingLearningPathway,
        pathway,
        updateCompleted,
        pathwayRetrieveError,
    } = useContext(LearningPathwayContext);

    return {
        loadingLearningPathway,
        updateCompleted,
        pathway,
        pathwayRetrieveError,
    };
}
