import React from "react";
import { Divider, Title } from "../../../components";
import { ContentContainer, PageContainer } from "../../../containers";
import graphicSrc from "../../../assets/images/tasks.svg";

export default function BackgroundCheckDone() {
  return (
    <PageContainer>
      <Title>Background Check</Title>

      <Divider />

      <ContentContainer>
        <div className="flex flex-col items-center space-y-10">
          <img className="max-w-xs" src={graphicSrc} alt="meeting graphic" />

          <div className="text-center">
            <p>Thank you for completing your background check!</p>
            <p>
              You're now one step closer to becoming a Step Up Tutor and making
              a difference in a child's life!
            </p>
          </div>
        </div>
      </ContentContainer>
    </PageContainer>
  );
}
