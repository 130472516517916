import {useCallback, useEffect, useState} from "react";
import {Alert, CircularLoader, StepUpLogoHorizontal} from "../../components";
import {useMagicAuth, useRouter} from "../../hooks";
import {LoaderPage} from "../../pages";
import {ALERT_VARIANTS, EXTERNAL_LINKS} from "../../constants/constants";

// TODO: Refactor
export default function MagicSignInPage() {
  const [alert, setAlert] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { credentials, sendMagicLink, signin, loadingCredentials } = useMagicAuth();
  const { location, navigate, query } = useRouter();

  const setSuccessAlert = (message) =>
    setAlert({ variant: ALERT_VARIANTS.SUCCESS, message });

  const setFailureAlert = (message) =>
    setAlert({ variant: ALERT_VARIANTS.FAILURE, message });

  const handleOnClose = () => setAlert(null);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsSending(true);

    const email = event.target.email.value;
    const returnUrl = location?.state?.from;

    const res = await sendMagicLink(email, returnUrl);
    if (res?.ok) {
      setSuccessAlert("Success! Magic link sent.");
    } else {
      const { code, message } = res.error;
      setFailureAlert(`${code} ${message}`);
    }

    setIsSending(false);
  };

  const handleMagicSignIn = useCallback(
    async (token, redirectUrl) => {
      setIsLoading(true);
      const res = await signin(token);
      if (res?.ok) {
        navigate.replace({ to: redirectUrl || "/onboarding" });
      } else {
        const { code, message } = res.error;
        setFailureAlert(`${code} ${message}`);
      }
    },
    [navigate, signin]
  );

  // TODO
  const handleUserRegistration = () => {};

  useEffect(() => {
    if (!loadingCredentials)
    if (!credentials) {
      const { email, stepupToken, returnUrl } = query;

      (async () => {
        if (email) await handleUserRegistration(email);
        else if (stepupToken) await handleMagicSignIn(stepupToken, returnUrl);
      })();
    }else {
      navigate.replace({ to:  "/onboarding" });
    }
  }, [credentials, handleMagicSignIn, query, loadingCredentials, navigate]);

  if (isLoading || loadingCredentials) {
    return <LoaderPage />;
  }

  return (
    <>
      {alert && (
        <div className="relative w-1/2 m-auto">
          <Alert {...alert} onClose={handleOnClose} dismissable>
            <p>{alert.message}</p>
          </Alert>
        </div>
      )}
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <StepUpLogoHorizontal />
          <div className="my-3 pl-5 text-center text-xs font-semibold text-gray-900 uppercase tracking-wider">
            <h1>Onboarding Portal</h1>
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign In
          </h2>
          <p className="mt-6 text-center text-sm text-gray-600">
            Enter your email address and get a magic link sent to your email
            that'll sign you in instantly. No passwords needed!
          </p>
        </div>

        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleFormSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                {isSending ? (
                  <button
                    type="submit"
                    disabled
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-turquoise hover:bg-turquoise-dark cursor-progress">
                    <span className="flex justify-center">
                      <CircularLoader className="w-4 h-4" />
                      Sending Magic Link
                    </span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-turquoise hover:bg-turquoise-dark">
                    Send Magic Link
                  </button>
                )}
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    Don't have an account?
                  </span>
                </div>
              </div>

              <div className="mt-6">
                <a
                  href={EXTERNAL_LINKS.VOLUNTEER_TUTOR_APPLICATION}
                  target="_blank"
                  rel="noreferrer noopener">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-turquoise-dark rounded-md shadow-sm text-sm font-medium text-turquoise-dark bg-white hover:text-white hover:bg-turquoise-dark hover:border-transparent">
                    Start Application
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
