import { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";

export default function useRouter() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  return useMemo(
    () => ({
      location,
      navigate: {
        push: ({ to, state }) => navigate(to, { state }),
        replace: ({ to, state }) => navigate(to, { state, replace: true }),
        go: (n) => navigate(n),
        goBack: () => navigate(-1),
        goForward: () => navigate(1),
      },
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
    }),
    [location, navigate, params]
  );
}
