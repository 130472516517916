import React, {Fragment, useState} from "react";
import {useOutlet} from "react-router-dom";
import {Dialog, Transition} from "@headlessui/react";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import {Sidebar} from "../../containers";
import {Loader} from "../../components";
import bigwave from "../../assets/images/logo-wave.png"

export default function SidebarLayout(props) {
  const { navigation, onSignOut, title, user } = props;
  const outlet = useOutlet();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div>
        {/* Mobile Sidebar */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute top-0 right-0 -mr-12">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <>
                  <Sidebar navigation={navigation} onSignOut={onSignOut} title={title} user={user} />
                </>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
          </Dialog>
        </Transition.Root>

        {/* Desktop Sidebar */}
        <div
      className="absolute top-0 z-50 pl-1 h-20 bg-no-repeat bg-center bg-cover sm:hidden w-full"
      style={{ backgroundImage: `url("${bigwave}")` }}
    ></div>             
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          <Sidebar navigation={navigation} onSignOut={onSignOut} title={title} user={user} />
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div>
            <button
              type="button"
              className="-ml-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 md:hidden hover:text-gray-900"
              onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="pt-[40px]">{outlet ? outlet : <Loader />}</main>
        </div>
      </div>
    </>
  );
}
