import React from "react";
import classNames from "../../../utils/classNames";
import { Link } from "react-router-dom";
import TaskGridItemBadge from "./TaskGridItemBadge";
import { ClockIcon } from "@heroicons/react/solid";
import { TASK_STATUS } from "../../../constants/constants";
import { CheckIcon, XIcon } from "@heroicons/react/outline";

const STATUS_BADGE_STYLES = {
  [TASK_STATUS.COMPLETE]: {
    icon: CheckIcon,
    colorForeground: "text-white",
    colorBackground: "bg-turquoise",
  },
  [TASK_STATUS.INCOMPLETE]: {
    icon: XIcon,
    colorForeground: "text-rose-700",
    colorBackground: "bg-rose-100",
  },
};

export default function TaskGridItem(props) {
  const { action, index, length, status } = props;

  return (
    <div
      key={action.name}
      className={classNames(
        index === 0 ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none" : "",
        index === 1 ? "sm:rounded-tr-lg" : "",
        index === length - 2 ? "sm:rounded-bl-lg" : "",
        index === length - 1
          ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
          : "",
        "relative group bg-white p-6 hover:bg-gray-100"
      )}>
      <div className="flex items-center">
        <span
          className={classNames(
            action.iconBackground,
            action.iconForeground,
            "mr-4 rounded-lg inline-flex p-3 ring-4 ring-white"
          )}>
          <action.icon className="h-6 w-6" aria-hidden="true" />
        </span>
        <h3 className="text-lg font-medium">
          <Link to={action.link} className="">
            {/* Extend touch target to entire panel */}
            <span className="absolute inset-0" aria-hidden="true" />
            {action.name}
          </Link>
        </h3>
      </div>
      <div className="my-2">
        <p className="text-sm text-gray-500">{action.description}</p>
      </div>
      <div className="space-x-2">
        {/*Status*/}
        {action.tracked && (
          <TaskGridItemBadge
            label={status}
            icon={STATUS_BADGE_STYLES[status].icon}
            colorForeground={STATUS_BADGE_STYLES[status].colorForeground}
            colorBackground={STATUS_BADGE_STYLES[status].colorBackground}
            colorOutline={STATUS_BADGE_STYLES[status].colorOutline}
          />
        )}

        {/*Time*/}
        {action.time && (
          <TaskGridItemBadge
            label={action.time}
            icon={ClockIcon}
            colorForeground="text-neutral-700"
            colorBackground="bg-neutral-100"
          />
        )}
      </div>
    </div>
  );
}
