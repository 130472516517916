import React, {useCallback, useEffect, useState} from "react";

const Activities = (props) => {
  console.log("IntroActivity props", props);

  const [currentActivity, setCurrentActivity] = useState(null);

  const activityIds = Object.keys(props.activities);

  const getRandomActivity = useCallback(() => {
    if (activityIds.length === 0) {
      setCurrentActivity({ description: "Please ask your student about their day." });
      return;
    }

    const randomIndex = Math.floor(Math.random() * activityIds.length);
    const randomId = activityIds[randomIndex];
    setCurrentActivity(props.activities[randomId]);
  }, [activityIds, props.activities]);

  // Initialize with a random activity
  useEffect(() => {
    if (!currentActivity) {
      getRandomActivity();
    }
  }, [currentActivity, getRandomActivity]);

  return (
    <div className="p-5 relative">
      <button className="absolute top-5 left-20" onClick={getRandomActivity}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg>
      </button>
      <p className="ml-16 pl-8">
        {currentActivity ? currentActivity.description : "Please ask your student about their day."}
      </p>
    </div>
  );
};

export default Activities;
