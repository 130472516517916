import React from "react";
import logoSrc from "../../../assets/images/logo-sut-horizontal.png";
import logoTeal from "../../../assets/images/logoTeal.png";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants/constants";

export default function Logo(props) {
  const { theme, linkTo = ROUTE_PATHS.LANDING } = props
  return (
    <Link to={linkTo}>
      {theme === 'green' ? (
        <img className="h-16 w-auto" src={logoTeal} alt="logo" />
      ) : (<img className="h-16 w-auto" src={logoSrc} alt="logo" />
      )}
    </Link>
  );
}
