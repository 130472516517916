import { useEffect, useState } from "react";
import StudentGrid from "../StudentGrid";
import { Divider, Heading, Subtitle, Title } from "../../../components";
import Modal from '../../../components/core/Modal';
import { useTutoringUser } from "../../../hooks";
import { BookOpenIcon, ClockIcon, UserGroupIcon } from "@heroicons/react/outline";
import { ContentContainer, PageContainer } from "../../../containers";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import Notification from "../Notification";
import TutorError from "../TutorError";
import LoaderPage from '../../utility/LoaderPage'
import useFomo from '../../../hooks/useFomo'
import SupportChannels from "../SupportChannels";

export default function Dashboard() {
  useDocumentTitle('Dashboard')
  const [, setStats] = useState([]);
  const { user, tutorError } = useTutoringUser();
  const [studentInfo, setStudentInfo] = useState(null);
  useFomo()
  
  useEffect(() => {
    if (!user) return;
    const students = user.correspondents || [];
    const tutoringStats = [
      {
        name: "Students",
        icon: UserGroupIcon,
        stat: students.length || 0,
      },
      {
        name: "Sessions",
        icon: BookOpenIcon,
        stat: user.totalSessions || 0,
      },
      {
        name: "Minutes",
        icon: ClockIcon,
        stat: user.minutesTutored || 0,
      },
    ];
    setStats(tutoringStats);
  }, [user, user?.correspondents]);

  if (tutorError) {
    return <TutorError />
  } else if (!user) {
    return <LoaderPage />
  } else {
    return (
      <>
        <PageContainer>
          <Title text="Tutor Dashboard" theme='green' />
          <Subtitle user={user} />

          <ContentContainer>
            <Heading />
          </ContentContainer>

          <Divider size="small" />

          <ContentContainer>
            <StudentGrid setStudentInfo={setStudentInfo} />
          </ContentContainer>
          <ContentContainer>
            <SupportChannels />
          </ContentContainer>
        </PageContainer>
        <Notification user={user} />
        {studentInfo && (
          <Modal close={() => { setStudentInfo(null) }} title="Student Information">
            <StudentModal studentInfo={studentInfo} setStudentInfo={setStudentInfo} />
          </Modal>
        )}
      </>
    );
  }
}

const StudentModal = ({ studentInfo, setStudentInfo }) => {
  const meetingID = studentInfo.information['Student Meeting ID'];
  const meetingLink = "https://student-portal.stepuptutoring.org/student/" + meetingID;

  const filteredInfo = Object.entries(studentInfo.information).filter(
    ([key]) => key !== 'Student Meeting Link'
  );

  const copyToClipboard = () => {
    navigator.clipboard.writeText(meetingLink);
    alert('Meeting link copied to clipboard');
  };

  return (
    <>
      {meetingLink && (
        <><div className="font-bold mb-2">Student Meeting Link</div><div className="mb-4 flex items-center">
          <input
            type="text"
            value={meetingLink}
            readOnly
            className="flex-1 p-2 border rounded mr-2"
            style={{ whiteSpace: 'pre-line' }} />
          <button
            onClick={copyToClipboard}
            className="p-2 bg-gray-400 text-white rounded"
          >
            Copy
          </button>
        </div></>
      )}
      <ul>
        {filteredInfo.map(([key, value]) => (
          <li className="mt-3" key={key}>
            <span className="font-bold">{key}:</span> {value}
          </li>
        ))}
      </ul>
    </>
  );
};
