import _ from "lodash";
import React, {useEffect, useState} from "react";
import onboardingNav from "../config/navigation/onboarding";
import steps from "../config/content/onboarding-progress-steps";
import {useOnboardingUser} from "../hooks";
import {ONBOARDING_STATUS_INDEX, STEPS_STATUS} from "../constants/constants";

const SIDEBAR_TITLE = "Onboarding";

export default function withOnboardingData(Component) {
  return (props) => {
    const { signout } = props;
    const [currentStepIdx, setCurrentStepIdx] = useState([null]);
    const { user, isOnboardingTaskCompleted } = useOnboardingUser();
    const [, setOnboardingSteps] = useState([]);
    const [ onboardingTasks, setOnboardingTasks] = useState([]);

    useEffect(() => {
      if (!user) return;
      setCurrentStepIdx(ONBOARDING_STATUS_INDEX[user.status]);
    }, [user]);

    useEffect(() => {
      if (currentStepIdx === null || !steps) return;
      const userSteps = _.map(steps, (step, idx) => {
        if (currentStepIdx === idx) {
          step.status = STEPS_STATUS.CURRENT;
        } else if (currentStepIdx > idx) {
          step.status = STEPS_STATUS.COMPLETE;
        } else {
          step.status = STEPS_STATUS.PENDING;
        }
        return step;
      });
      setOnboardingSteps(userSteps);
    }, [currentStepIdx]);

    useEffect(() => {
      if (!user || !onboardingNav) return;
      const tasks = _.map(onboardingNav[0].children, (child) =>
        isOnboardingTaskCompleted(child.id),
      );

      setOnboardingTasks(tasks);
    }, [user, isOnboardingTaskCompleted]);

    const len = onboardingNav[0].children.length;
    for (let i = 0; i < len; i++) {
      onboardingNav[0].children[i].isCompleted = onboardingTasks[i];
    }

    onboardingNav[0].step = currentStepIdx;

    return (
      <Component
        {...props}
        navigation={onboardingNav}
        onSignOut={signout}
        title={SIDEBAR_TITLE}
        user={user}
      />
    );
  };
}
