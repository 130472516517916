import React from "react";

export default function CircularIcon(props) {
  const { children } = props;
  return (
    <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-black rounded-full">
      <span>{children}</span>
    </span>
  );
}
