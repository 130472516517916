import React from "react";

export default function Card(props) {
  const { children, footer, header } = props;

  return (
    <div className="bg-white overflow-hidden border rounded-3xl divide-y divide-gray-200">
      {header && <div className="px-4 py-5 sm:px-6">{header}</div>}
      <div className="px-4 py-5 sm:p-6">{children}</div>
      {footer && <div className="px-4 py-4 sm:px-6">{footer}</div>}
    </div>
  );
}
