import React from "react";
import tutoringNav from "../config/navigation/tutoring";
import { useTutoringUser } from "../hooks";

const SIDEBAR_TITLE = "Tutoring";

export default function withTutoringData(Component) {
  return (props) => {
    const { signout } = props;
    const { user } = useTutoringUser();

    return (
      <Component
        {...props}
        navigation={tutoringNav}
        onSignOut={signout}
        title={SIDEBAR_TITLE}
        theme='green'
        user={user}
      />
    );
  };
}
