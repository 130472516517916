import React, {useMemo, useState} from 'react';
import {ExternalLink, SolidButton} from "../../index";
import Lessons from "./Lessons";

function AssessmentResultDetail({ assessmentResult, pathway, studentId }) {

    const [isPreCopied, setIsPreCopied] = useState(false);
    const [isPostCopied, setIsPostCopied] = useState(false);
    const student = pathway?.student_obj;
    const preAssessmentUrl = `${pathway['Next_Pre_Assessment_URL']}?id=${studentId}&name=${student.firstname}&grade=${student.grade}`
    const postAssessmentUrl = `${pathway['Post_Assessment_URL']}?id=${studentId}&name=${student.firstname}&grade=${student.grade}`


    const handleCopyClick = async (url, setCopiedState) => {
        await navigator.clipboard.writeText(url);
        setCopiedState(true);
    };

    const postAssessmentText = useMemo(() => {
        if (!pathway['Post_Assessment']) {
            return null;
        }
    
        let message = "";
        const title = <h2 className="text-blueGreen text-left mb-4 text-xl font-bold">Post-Assessment</h2>;
    
        const additionalContent = !assessmentResult ? (
            <div className='mt-10' onClick={() => handleCopyClick(postAssessmentUrl, setIsPostCopied)}>
                <SolidButton theme='green' className='mt-10'>
                    {isPostCopied ? 'Copied!' : 'Copy to Clipboard'}
                </SolidButton>
            </div>
        ) : null;
    
        if (assessmentResult === "Failed") {
            // message = (<p> Thank you for completing the <ExternalLink href={postAssessmentUrl} className="text-turquoise-dark underline">{pathway['Post_Assessment']}</ExternalLink>! Based on Post-Assessment results, Jayden has moved from {pathway["Pre Assessment score"]}% to {pathway["Post Assessment score"]}% on {pathway["Pathway_Name"]}. We have assigned a few more lessons to instill these concepts further...</p>);
            message = (<p> Thank you for completing the <ExternalLink href={postAssessmentUrl} className="text-turquoise-dark underline">Post-Assessment</ExternalLink>! Based on Post-Assessment results, Jayden has shown some improvement, but we have assigned a few more lessons to instill these concepts further...</p>);

        } else if (assessmentResult === "Passed") {
            // message = (<p> Thank you for completing the <ExternalLink href={postAssessmentUrl} className="text-turquoise-dark underline">{pathway['Post_Assessment']}</ExternalLink>. {student?.firstname} has shown improvement, moving from {pathway["Pre Assessment score"]}% to {pathway["Post Assessment score"]}% and now demonstrates proficiency in the concepts covered in this learning pathway. Great job!</p>);
            message = (<p> Thank you for completing the <ExternalLink href={postAssessmentUrl} className="text-turquoise-dark underline">Post-Assessment</ExternalLink>. {student?.firstname} has shown great improvement and now demonstrates proficiency in the concepts covered in this learning pathway. Great job!</p>);

        } else {
            message = (
                <div>
                  <p>
                    Once you have checked off all the lessons from the learning pathway, you are ready to administer {student.firstname}'s personalized
                    <ExternalLink href={postAssessmentUrl} className="text-turquoise-dark underline">
                      Post-Assessment
                    </ExternalLink>
                    . Make sure you allot at least 30 minutes to complete the assessment. Copy the link and paste it into the chat. Ask {student.firstname} to share their screen while they complete the assessment. Once they complete it, you may have to wait a couple minutes for our system to process the results.
                  </p>
                  <p>
                    Note: If you have any problems with your assessment, use the <a href="https://docs.google.com/document/d/1YD0_uR6IAadwpwYaFm_WOJG3cGusmSphLI8J5RTJECY/edit?usp=sharing" className="text-turquoise-dark underline">general learning pathway guide</a>
                    .
                  </p>
                </div>
              )        }
    
        return (
            <div>
                {title}
                <div className='ml-8'>
                    {message}
                {additionalContent}
                </div>
            </div>
        );
    }, [pathway, assessmentResult, isPostCopied, postAssessmentUrl, student.firstname]);
    

    const preAssessmentText = useMemo(() => {
        if (assessmentResult || (!assessmentResult && !pathway['Post_Assessment'])) {
            return (
                <>
                    Take the <ExternalLink href={preAssessmentUrl} className="text-turquoise-dark underline"> Tutor Survey</ExternalLink> to create generate a new personalized learning pathway for {student?.firstname}. Make sure you have dedicated at least 30 minutes to complete the assessment. Copy the link and paste it into the chat and then ask {student?.firstname} to share their screen while they complete the assessment.  Note: If you have any problems with your assessment, use the <a href="https://docs.google.com/document/d/1YD0_uR6IAadwpwYaFm_WOJG3cGusmSphLI8J5RTJECY/edit?usp=sharing" className="text-turquoise-dark underline">general learning pathway guide</a>
                </>
            )
        }
    }, [assessmentResult, pathway, preAssessmentUrl, student?.firstname]);



    const showPreAssessmentSection = (assessmentResult && pathway['Next_Pre_Assessment_URL']) ||
        (!assessmentResult && !pathway['Post_Assessment'] && pathway['Next_Pre_Assessment_URL']);


    const preAssesmentSection = useMemo(() => {
        if (showPreAssessmentSection) {
            return (
                <div>
                    <div className={'mt-10 text-left w-fit'}>
                        <h2 className={"mb-4 text-blueGreen text-center text-xl font-bold leading-6"}>Next Unit Pre-Assessment</h2>
                    </div>
                    <div className={'mt-10 ml-6'}>
                        <p>{preAssessmentText}</p>
                    </div>
                    <div className='mt-10 ml-8' onClick={() => handleCopyClick(preAssessmentUrl, setIsPreCopied)}>
                        <SolidButton theme='green' className='mt-10'>
                            {isPreCopied ? 'Copied!' : 'Copy to Clipboard'}
                        </SolidButton>
                    </div>
                </div>
            );
        }
        return null;
    }, [showPreAssessmentSection, preAssessmentText, isPreCopied, preAssessmentUrl]);

    if (assessmentResult === "Failed")
        return (
            <div>


                <div className={'mt-10'}>
                    {postAssessmentText}
                </div>
                {pathway['Additional_lessons'] && pathway['Additional_lessons'].length > 0 && <>
                    <div className={'mt-10 text-left w-fit'}>
                        <h2 className={"mb-4 text-blueGreen text-center text-xl font-bold leading-6"}>Additional Lessons</h2>
                    </div>

                    <Lessons lesson={pathway['Additional_lessons']} completedLessons={pathway['completed_lessons']} />

                </>}
                <div>
                    {preAssesmentSection}
                </div>
            </div>
        );
    if (assessmentResult === "Passed")
        return (
            <div>
                <div className={'mt-10'}>
                    {postAssessmentText}
                </div>
                <div>
                    {preAssesmentSection}
                </div>


            </div>
        );
    return (
        <div>
            <div className={'mt-10'}>
                {postAssessmentText}
            </div>
            <div>
                {preAssesmentSection}
            </div>
        </div>
    );
}
export default AssessmentResultDetail;