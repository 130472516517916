import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { LogoutIcon } from "@heroicons/react/solid";
import { UserAvatar } from "../../../../components";
import { Link } from "react-router-dom";
import classNames from "../../../../utils/classNames";

export default function AccountMenu(props) {
  const { user, theme } = props;

  return (
    <div className="w-56">
      <Menu as="div" className="relative inline-block">
        <div>
          <Menu.Button className="inline-flex w-full text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <div className="flex items-center">
              <div className="w-9 h-9">
                <UserAvatar theme={theme}/>
              </div>

              <div className={classNames(
                theme === 'green' ? "mb-5" : "", "ml-3 text-left"
              )}>
                <p className="text-sm font-normal text-textGrey group-hover:text-gray-900">
                  {`${user.firstname || ""} ${user.lastname || ""}`}
                </p>
                <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{user.email || ""}</p>
              </div>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className='-top-2 transform -translate-y-full absolute left-0 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"'>
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  /** Adding routing to logout */
                  <Link to="/logout"
                    className={`${
                      active ? "bg-turquoise text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}>
                    {active ? (
                      <LogoutIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                    ) : (
                      <LogoutIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                    )}
                    Sign Out
                  </Link>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
