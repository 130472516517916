import React from "react";
import { ExternalLink, Title } from "../../index";
import AssessmentResultDetail from "./AssessmentResultDetail";
import Lessons from "./Lessons";
import Accordion from "../../core/Accordion";
import Activities from "../Activities/Activities.js";

function PathwayView({ pathway, studentName, pathwayRetrieveError, studentId }) {
  const assessmentResult = pathway["Assessment_result"];
  const includes = pathway.Pathway_Name?.endsWith("First Sessions") || false;
  const makeHyperlink = (description) => {
    const regex = /(https?:\/\/docs\.google\.com\/[^\s]+)/g;
    const parts = description?.split(regex);
  
    return parts?.map((part, index) => {
      if (part.match(regex)) {
        return (
          <ExternalLink key={index} href={part} className="text-turquoise-dark underline">
            Assigned Learning Pathway
          </ExternalLink>
        );
      }
      return part;
    });
  };

  const parsedDescription = makeHyperlink(pathway["Intro_description"]);

  return (
    <div className="mb-20">
      <div className="pl-[4em] pr-[5em] mx-auto py-14 shadow-sm bg-white border rounded-3xl max-w-6xl my-4">
        <div>
          <div className="text-left w-fit">
            <Title large={true} text={`${studentName}'s Learning Hub`} theme="green" />
          </div>
          {!includes && (
            <>
              <div className="mt-10">
                <p>{parsedDescription}</p>
              </div>
              <div className="mt-10 text-left w-fit">
                <h2 className="mb-4 text-blueGreen text-center text-xl mt-2 font-bold leading-6">Session Structure</h2>
              </div>
              <Accordion
                title={
                  <span>
                    Check In <span style={{ fontWeight: 200 }}>[5 minutes]</span>
                  </span>
                }
                content={<Activities activities={pathway["introActivities"]} />}
              />

              <Accordion
                title={
                  <span>
                    Lessons and Tasks <span style={{ fontWeight: 200 }}>[30-40 minutes]</span>
                  </span>
                }
                content={
                  <p className="ml-16 p-5 text-md">
                    Go through the topics in the listed order, clicking on the Featured Lesson link for each. These
                    lessons usually take around 60 minutes, but pace can vary per tutee. Continue from where you left
                    off in the next session and check it off when completed. If {studentName} gets antsy or bored, try taking a couple
                    minutes to take a couple deep breaths.
                  </p>
                }
              />

              <Accordion
                title={
                  <span>
                    Wrap Up <span style={{ fontWeight: 200 }}>[5 minutes]</span>
                  </span>
                }
                content={
                  <p className="ml-16 p-5 text-md">
                    Conclude the session by inviting {studentName} to share their thoughts on today's lesson and set
                    objectives for the next meeting. If you have extra time, you can also spend a few minutes reviewing
                    their schoolwork in platforms like Google Classroom or Schoology, highlighting any outstanding
                    assignments and reminding them to study for upcoming quizzes.
                  </p>
                }
              />
            </>
          )}
        </div>
      </div>
      {pathway["Lessons"].length > 0 && (
        <div
          className="pl-[4em] pr-[5em] mx-auto py-12 pr-12 shadow-sm bg-white border rounded-3xl max-w-6xl my-4">
          <div className="text-left w-fit">
            <h2 className="mb-4 text-blueGreen text-center text-xl font-bold leading-6">Lessons and Tasks</h2>
          </div>
          <div>
            <Lessons lesson={pathway["Lessons"]} completedLessons={pathway["completed_lessons"]} />
          </div>
          <div className="mt-10 mb-8 text-left w-fit">
            <AssessmentResultDetail assessmentResult={assessmentResult} pathway={pathway} studentId={studentId} />
          </div>
        </div>
      )}
    </div>
  );
}

export default PathwayView;
