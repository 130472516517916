import React, { useState, useMemo, useCallback } from "react";
import Accordion from "../../core/Accordion";
import useLearningPathway from "../../../hooks/useLearningPathway";
import Lesson from "./Lesson";

function Lessons({ lesson, completedLessons }) {
  const [checkedLessons, setCheckedLessons] = useState(completedLessons || {});
  const { updateCompleted } = useLearningPathway();

  const handleCheckboxChange = useCallback((lessonId) => {
    if (!checkedLessons[lessonId]) {
      updateCompleted?.(lessonId);
      setCheckedLessons((prevCheckedLessons) => ({
        ...prevCheckedLessons,
        [lessonId]: true,
      }));
    }
  }, [checkedLessons, updateCompleted]);

  const sortedLessons = useMemo(() => lesson.sort((a, b) => a.Lesson_Id.localeCompare(b.Lesson_Id)), [lesson]);

  const lessonsByTopic = useMemo(() => sortedLessons.reduce((topics, lesson) => {
    const topic = lesson.Topic;
    if (!topics[topic]) {
      topics[topic] = [];
    }
    topics[topic].push(lesson);
    return topics;
  }, {}), [sortedLessons]);

  return (
    <div>
      <p className="italic text-sm">Be sure to complete every item!</p>
      {Object.entries(lessonsByTopic).map(([topic, lessons]) => (
        <Accordion
          style={{ marginLeft: "0px" }}
          title={topic}
          key={topic}
          content={
            <div className="pl-8">
              {lessons.map((lesson, index) => (
                <Lesson
                  key={lesson["id"]}
                  lesson={lesson}
                  lessonNumber={index + 1} // Add 1 because index is 0-based, but we want lesson numbers to start from 1
                  checkedLessons={checkedLessons}
                  handleCheckboxChange={handleCheckboxChange}
                />
              ))}
            </div>
          }
        />
      ))}
    </div>
  );
}

export default Lessons;
