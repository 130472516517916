import React from "react";

export default function SmallButton(props) {
  const { children, name, type, click, disabled = false } = props;


  return (
    <button disabled={disabled} onClick={click} className={
      type && type === "solid" ? "text-center px-3 py-1 rounded-md shadow-md text-white bg-turquoise hover:bg-turquoise-dark w-3/4 md:w-fit" :
      "inline-flex items-center px-2 py-0.5 rounded-md border border-gray-300 text-sm text-gray-700 hover:bg-gray-50"
      }>
      {children || name}
    </button>
  );
}
